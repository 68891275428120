import React, {useState, useEffect, useRef, useMemo, useContext} from "react";
import PropTypes from "prop-types"
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import {Button, Col, Row} from "react-bootstrap";
import UserModel from "../../../../models/User";
import {FullContrastPurpleTextField, isEmail, isPhone, Modules, TourType} from "../../../../Utils/Global";
import EbloomSelectLang from "../../../Core/Modules/Input/EbloomSelectLang";
import {MenuItem} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {ReactComponent as TrashIcon} from "../../../Core/symbols/webloom/white/delete-white.svg"
import {ReactComponent as AlertIcon} from "../../../Core/symbols/webloom/white/danger-white.svg";
import {ReactComponent as ValidIcon} from "../../../Core/symbols/webloom/white/validate-white.svg";
import {useOutsideAlerter} from "../../../../hooks/outsideAlerterHook";
import ConfirmModal from "../../../Core/Modules/Modal/ConfirmModal";
import IconButton from "@mui/material/IconButton/IconButton";
import EbloomTooltip from "../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {getLanguages} from "../../../../lang/translations";
import HttpApi from "../../../../httpApi";
import {useUserData} from "../../../../hooks/userHook";
import {ProductTourContext} from "../../../../Utils/Context/ProductTourContext";
import {notifyError, notifySuccess} from "../../../../Utils/Notification";
import LoadingView from "../../../Core/Layouts/LoadingView";

const InviteEditor = (props) => {

    //state
    const [loadingSave, setLoadingSave] = useState(false);
    const [showModalRemove, setShowModalRemove] = useState(false);
    const [showModalPhone, setShowModalPhone] = useState(false);

    const [filters, setFilters] = useState([]);
    const [teams, setTeams] = useState([]);
    const [companies, setCompanies] = useState([]);

    const [users, setUsers] = useState([]);
    const [userToRemove, setUserToRemove] = useState(-1);
    const [activeIndex, setActiveIndex] = useState(0);

    //context
    const {updateStep,tourStarted,stepInvite} = useContext(ProductTourContext)

    //props
    const {createdEmailsAndPhones, usersTable, handleChangeUsers, handleButton, buttonText, isInvite, reloadFilters, handleSeePreviewAndReload, isBackoffice} = props;

    //hooks
    const t = useTranslation();
    let wrappedRef = useRef(null);
    const userData = useUserData();

    //getters

    //posters
    const saveUsers = () => {
        if(!loadingSave){
            setLoadingSave(true);
            const url = "/users/saveUsers";
            HttpApi.postV2(url, {users:users}).then(() => {
                notifySuccess(_.upperFirst(t("admin.invite.success.userSaved")));
                setLoadingSave(false);
                handleSeePreviewAndReload();
            }).catch(error => {
                setLoadingSave(false);
                notifyError(error);
            })
        }

    }

    //handlers
    const handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setUsers(prevState => {
            let users = [...prevState];
            let user = users[activeIndex]
            if(name.includes("filters")){
                const filterType = name.split("-")[1];
                const indexOf = user.filters.indexOf(user.filters.find(el => el.category === filterType));
                if(indexOf > -1){
                    user.filters.splice(indexOf, 1);
                }
                user.filters.push({id:null, name:value, category:filterType});
            }else{
                user[name] = value;
            }
            return users;
        })

        handleChangeUsers(users);
    }
    const handleAddRow = () => {
        if(tourStarted && Modules.HAPPY.includes(userData.module) && stepInvite === 1){
            updateStep(TourType.INVITE,2)
        }
        setUsers(prevState => {
            let users = [...prevState];
            const u = {email:"", name:"", lang:"en", team:null, filters:[]}
            users.push(u);
            handleChangeUsers(users);
            return users;
        })
        setActiveIndex(users.length);
    }

    const handleRemoveRow = () => {
        setUsers(prevState => {
            let users = [...prevState];
            users.splice(userToRemove, 1);
            handleChangeUsers(users);
            return users;
        })
    }

    const resetActiveIndex = () => {
        setActiveIndex(-1);
    }


    const handleOpenModalRemove = (index) => {
        setUserToRemove(index);
        setShowModalRemove(true);
    }

    const handleCloseModalRemove = () => {
        setUserToRemove(-1);
        setShowModalRemove(false);
    }

    const handleFindIssue = () => {
        const index = users.indexOf(users.find(el => validateUser(el) !== ""));
        const table = document.querySelectorAll("#tableRef .row");
        table[index].scrollIntoView({
            behavior:"smooth",
            block:"center"
        })
    }

    const validateUser = (user) => {
        let helperText = "";
        if(!user.email || (!isEmail(user.email) && !isPhone(user.email))){
            helperText += "* " + _.upperFirst(t("admin.invite.issues.invalidMailPhoneHelper")) + "\n";
        }else if(createdEmailsAndPhones.map(el => _.toLower(el.toString())).includes(_.toLower(user.email))){
            helperText += "* " + _.upperFirst(t("admin.invite.issues.alreadyUsedMailPhoneHelper")) + "\n";
        }else if(users.find(el => el !== user && _.toLower(el.email) === _.toLower(user.email))){
            helperText += "* " + _.upperFirst(t("admin.invite.issues.duplicateMailPhoneHelper")) + "\n";
        }
        if(!user.lang || !getLanguages().map(el => el.value).includes(user.lang)){
            helperText += "* " + _.upperFirst(t("admin.invite.issues.invalidLangHelper")) + "\n";
        }
        if(!isBackoffice){
            if(user.filters.filter(el => el.name).length < filters.length){
                helperText += "* " + _.upperFirst(t("admin.invite.issues.invalidFiltersHelper")) + "\n";
            }
        }

        if(isBackoffice && !user.company){
            helperText += "* " + _.upperFirst(t("admin.invite.issues.invalidCompanyHelper")) + "\n";
        }

        return helperText;
    }

    const countInvalidUsers = useMemo(
        () => {
            let count=0;
            for(let i =0;i<users.length;i++){
                if(validateUser(users[i]) !== ""){
                    count++;
                }
            }
            return count;
        }, [users]
    )

    //useEffect

    useEffect(() => {
        if(!isBackoffice){
            if(!usersTable || usersTable.length === 0){
                let filters = UserModel.filters.form.selectOptions.options;
                if(filters){
                    let company_filters = filters.map(el => el.company_filter);
                    company_filters = [...new Set(company_filters)];//remove duplicates;
                    let l = company_filters.map(el => ({"company_filter":el, "filters":filters.filter(item => item.company_filter === el)}))
                    setFilters(l);
                }
            }
            let teams = UserModel.id_team.form.selectOptions.options;
            if(teams){
                setTeams(teams);
            }
        }
    }, [])

    useEffect(() => {
        if(!isBackoffice){
            if(usersTable && usersTable.length > 0 && (!users || users.length === 0)){
                let newUsers = _.cloneDeep(usersTable);
                let t = newUsers.map(el => el.team);
                t = [...new Set(t)]; //remove duplicate
                for(let i=0;i<t.length;i++){
                    if(t[i] && teams.find(el => el.name === t[i]) === undefined){
                        setTeams(prevState => {
                            let teams = [...prevState];
                            teams.push({name:t[i]});
                            return teams;
                        })
                    }
                }
                let f = newUsers.map(el => el.filters);
                let copyFilters =_.cloneDeep(filters);
                if(!copyFilters || copyFilters.length === 0){
                    let optionFilters = UserModel.filters.form.selectOptions.options;
                    if(optionFilters){
                        let company_filters = optionFilters.map(el => el.company_filter);
                        company_filters = [...new Set(company_filters)];//remove duplicates;
                        copyFilters = company_filters.map(el => ({"company_filter":el, "filters":optionFilters.filter(item => item.company_filter === el)}))
                    }
                }
                for(let i=0;i<f.length;i++){
                    f[i].forEach(item => {
                        if(item.name !== null && item.name !== "" && item.name !== undefined){
                            let copyFilter = copyFilters.find(el => el.company_filter === item.category);
                            if(copyFilter !== undefined){
                                if(copyFilter.filters.find(el => el.name === item.name) === undefined){
                                    copyFilter.filters.push({id:null, name:item.name, company_filter:item.category});
                                }
                            }else{
                                copyFilters.push({company_filter:item.category, filters:[{id:null, name:item.name, company_filters:item.category}]})
                            }
                        }

                    })
                }
                setFilters(copyFilters);
                setUsers(newUsers);
                setActiveIndex(-1);
            }else if((usersTable && usersTable.length > 0) || reloadFilters){
                const newUsers = _.cloneDeep(usersTable);
                let f = newUsers.map(el => el.filters);
                let copyFilters = _.cloneDeep(filters);
                if(reloadFilters && copyFilters && copyFilters.length > 0){
                    copyFilters = copyFilters.filter(el => !reloadFilters.find(item => item.name === el.company_filter));
                }
                if(!copyFilters || copyFilters.length === 0){
                    let optionFilters = UserModel.filters.form.selectOptions.options;
                    if(optionFilters){
                        let company_filters = optionFilters.map(el => el.company_filter);
                        company_filters = [...new Set(company_filters)];//remove duplicates;
                        copyFilters = company_filters.map(el => ({"company_filter":el, "filters":optionFilters.filter(item => item.company_filter === el)}))

                    }
                }
                for(let i=0;i<f.length;i++){
                    f[i].forEach(item => {
                        if(item.name !== null && item.name !== undefined && item.name !== ""){
                            let copyFilter = copyFilters.find(el => el.company_filter === item.category);
                            if(copyFilter !== undefined){
                                if(copyFilter.filters.find(el => el.name === item.name) === undefined){
                                    copyFilter.filters.push({id:null, name:item.name, company_filter:item.category});
                                }
                            }else{
                                copyFilters.push({company_filter:item.category, filters:[{id:null, name:item.name, company_filters:item.category}]})
                            }
                        }

                    })
                }
                setFilters(copyFilters);
                setUsers(newUsers);
            }else{
                setUsers([]);
            }
        }else{
            setUsers(usersTable);
        }
    }, [usersTable, reloadFilters])

    useOutsideAlerter(wrappedRef, resetActiveIndex);

    return (
        <div data-invite={'resultExcel'}>
            <div className={"flex items-center justify-around mb-10"}>
                <div>{_.upperFirst(t("admin.invite.valid"))}<span className={"mx-5  text-strong-green font-semibold"}>{users.length - countInvalidUsers}</span></div>
                <div className={countInvalidUsers > 0 ? "ebloom-link-purple" : ""} onClick={countInvalidUsers > 0 ? handleFindIssue : () => {}}>{_.upperFirst(t("admin.invite.invalid"))}<span className={"mx-5 text-strong-red font-semibold"}>{countInvalidUsers}</span></div>
                <div>{_.upperFirst(t("admin.invite.total"))}<span className={"mx-5 text-contrast-purple font-semibold"}>{users.length}</span></div>
            </div>
            <div className={"max-h-500 overflow-y-auto"}>
                <div ref={wrappedRef} className={"flex"}>
                    <div className={"mr-5"}>
                        <div className={"h-50"}/>
                        {
                            users && users.map((user, index) => (
                                <Row key={index} className={"h-50 my-5"}>
                                    <Col className={"flex items-center"}>
                                        {
                                            validateUser(user) !== "" &&
                                            <EbloomTooltip text={validateUser(user)} placement={"bottom"}>
                                                <AlertIcon className={"h-20 w-20 red-icon"}/>
                                            </EbloomTooltip>
                                        }
                                        {
                                            index === activeIndex && validateUser(user) === "" &&
                                            <ValidIcon className={"h-20 w-20 green-icon"}/>
                                        }
                                        {
                                            index === activeIndex &&
                                            <IconButton size={"small"} onClick={() => {handleOpenModalRemove(index)}}>
                                                <TrashIcon className={"h-20 w-20 purple-icon"}/>
                                            </IconButton>

                                        }
                                    </Col>
                                </Row>
                            ))
                        }
                    </div>
                    <div id={"tableRef"} className={"overflow-x-auto"} style={{minWidth:800}}>
                        <Row className={"h-50 w-fit text-center text-contrast-purple font-semibold grid grid-rows-1"} style={{gridTemplateColumns:"repeat("+ (4+filters.length) +",200px)"}}>
                            <Col>{_.upperFirst(t(isBackoffice ? "core.login.email" : "core.login.emailPhoneNumber"))}</Col>
                            {
                                !isBackoffice &&
                                <Col>{_.upperFirst(t("core.login.nameOptional"))}</Col>
                            }
                            <Col>{_.upperFirst(t("core.login.lang"))}</Col>
                            {
                                isBackoffice &&
                                <Col>{_.upperFirst(t("admin.identity.company"))}</Col>
                            }
                            {
                                (userData.module !== Modules.HAPPY[0] && !isBackoffice) &&
                                <Col>{_.upperFirst(t("admin.team"))}</Col>
                            }
                            {
                                filters && filters.map((filter, index) => (
                                    <Col key={index}>{_.upperFirst(filter.company_filter)}</Col>
                                ))
                            }


                        </Row>

                        {
                            users && users.map((user, index) => (
                                activeIndex === index ?
                                    <Row data-invite={'rowUser'} className={"h-50 w-fit my-5 text-center grid grid-rows-1"} style={{gridTemplateColumns:"repeat("+ (4+filters.length) +", 200px)"}}>
                                        <Col>
                                            <FullContrastPurpleTextField variant={"outlined"} size={"small"} value={user.email} name={"email"} onChange={handleChange} placeholder={_.upperFirst(t("core.login.emailPhoneNumber"))} error={!user.email || (!isPhone(user.email) && !isEmail(user.email)) || createdEmailsAndPhones.includes(user.email) || users.filter(el => el !== user).map(el => el.email).includes(user.email)}/>
                                        </Col>
                                        {
                                            !isBackoffice &&
                                            <Col>
                                                <FullContrastPurpleTextField variant={"outlined"} size={"small"} value={user.name} name={"name"} onChange={handleChange} placeholder={_.upperFirst(t("core.login.name"))}/>
                                            </Col>
                                        }
                                        <Col>
                                            <EbloomSelectLang handleChange={handleChange} value={user.lang} error={!user.lang || !getLanguages().map(el => el.value).includes(user.lang)}/>
                                        </Col>
                                        {
                                            isBackoffice &&
                                            <Col>
                                                <FullContrastPurpleTextField variant={"outlined"} size={"small"} value={user.company || null} name={"company"} onChange={handleChange} select error={!user.company}>
                                                    <MenuItem key={-1} value={null} disabled>{_.upperFirst(t("core.select"))}</MenuItem>
                                                    {
                                                        companies?.map((company, index) => (
                                                            <MenuItem key={index} value={company.name}>{company.name}</MenuItem>
                                                        ))
                                                    }
                                                </FullContrastPurpleTextField>
                                            </Col>
                                        }
                                        {
                                            (userData.module !== Modules.HAPPY[0] && !isBackoffice) &&
                                            <Col>
                                                <FullContrastPurpleTextField variant={"outlined"} size={"small"} value={user.team || null} name={"team"} onChange={handleChange} select>
                                                    <MenuItem key={-1} value={null} disabled>{_.upperFirst(t("core.select"))}</MenuItem>
                                                    {
                                                        teams && teams.map((team, index) => (
                                                            <MenuItem key={index} value={team.name}>{team.name}</MenuItem>
                                                        ))
                                                    }
                                                </FullContrastPurpleTextField>
                                            </Col>
                                        }
                                        {
                                            filters && filters.map((filter, index) => (
                                                <Col key={index}>
                                                    <FullContrastPurpleTextField variant={"outlined"} size={"small"} value={user.filters.find(el => el.category === filter.company_filter) && user.filters.find(el => el.category === filter.company_filter).name !== null ? user.filters.find(el => el.category === filter.company_filter).name : null} name={"filters-" + filter.company_filter} onChange={handleChange} select error={!user.filters.find(el => el.category === filter.company_filter) || user.filters.find(el => el.category === filter.company_filter).name === null}>
                                                        <MenuItem key={-1} value={null} disabled>{_.upperFirst(t("core.select"))}</MenuItem>
                                                        {
                                                            filter.filters.map((item, index) => (
                                                                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                                            ))
                                                        }
                                                    </FullContrastPurpleTextField>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                    :
                                    <Row key={index} data-invite={'rowUser'} className={"h-50 w-fit pointer text-center my-5 hover:bg-bg-grey grid grid-rows-1 items-center"} style={{gridTemplateColumns:"repeat("+(4+filters.length)+", 200px)"}} onClick={() => {setActiveIndex(index)}}>
                                        <Col className={"truncate"}>
                                            {
                                                user.email || <EbloomTooltip text={_.upperFirst(t("admin.invite.issues.invalidMailPhoneHelper"))}><AlertIcon className={"w-20 h-30 red-icon m-auto"}/></EbloomTooltip>
                                            }
                                        </Col>
                                        {
                                            !isBackoffice &&
                                            <Col className={"truncate"}>{user.name}</Col>
                                        }
                                        <Col className={"truncate"}>{user.lang ?  _.upperFirst(t("core.lang." + user.lang)) : <EbloomTooltip text={_.upperFirst(t("admin.invite.issues.invalidLangHelper"))}><AlertIcon className={"w-20 h-30 red-icon m-auto"}/></EbloomTooltip>}</Col>
                                        {
                                            isBackoffice &&
                                            <Col className={"truncate"}>{user.company}</Col>
                                        }
                                        {
                                            (userData.module !== Modules.HAPPY[0] && !isBackoffice) &&
                                            <Col className={"truncate"}>{user.team}</Col>
                                        }
                                        {
                                            filters && filters.map((f, i) => (
                                                <Col key={i}>{user.filters.find(el => el.category === f.company_filter && el.name !== null) ? user.filters.find(el => el.category === f.company_filter).name : <EbloomTooltip text={_.upperFirst(t("admin.invite.issues.invalidFiltersHelper"))}><AlertIcon className={"w-20 h-20 red-icon m-auto"}/></EbloomTooltip>}</Col>
                                            ))
                                        }
                                    </Row>
                            ))
                        }
                    </div>
                </div>
            </div>

            {
                isInvite &&
                <EbloomTooltip text={_.upperFirst(t("core.add"))} placement={"left"}>
                    <div data-invite={'addButton'} className={"flex justify-start pointer mt-10 hover:bg-bg-grey text-grey rounded-md" + (!users || users.length === 0 ? " bg-purple/[0.1]" : "")} onClick={handleAddRow}>
                        {
                            users && users.length > 0 ?
                                <AddIcon className={"text-purple"}/>
                                :
                                <AddCircleIcon className={"text-purple"}/>
                        }

                    </div>
                </EbloomTooltip>
            }

            {
                (users && users.length > 0 && !isBackoffice) &&
                <div className={"flex justify-end items-center"}>
                    {
                        isInvite &&
                      <Button variant={"link"} disabled={countInvalidUsers > 0 || users.length === 0 || loadingSave} data-invite={"saveUsers"} className={"ebloom-link-purple mx-5"} onClick={saveUsers}>{loadingSave ? <LoadingView size={16}/> : _.upperFirst(t("core.saveUsers"))}</Button>
                    }
                    <Button data-invite={'seePreview'} className={"ebloom-btn-purple"} disabled={countInvalidUsers > 0 || users.length === 0} size={"sm"} onClick={users.find(el => isPhone(el.email)) ? () => {setShowModalPhone(true)} : handleButton}>{buttonText}</Button>
                </div>
            }
            {
                (users && users.length > 0 && isBackoffice) &&
                <div className={"flex justify-end items-center"}>
                    <Button className={"ebloom-btn-purple"} disabled={countInvalidUsers > 0 || users.length === 0 || loadingSave}  size={"sm"} onClick={saveUsers}>{loadingSave ? <LoadingView size={16}/> : _.upperFirst(t("core.saveUsers"))}</Button>
                </div>
            }
            <ConfirmModal handleClose={handleCloseModalRemove} show={showModalRemove} title={_.upperFirst(t("admin.invite.modalDeleteRowTitle"))} handleChange={handleRemoveRow} buttonRightText={_.upperFirst(t("core.confirm"))} buttonLeftText={_.upperFirst(t("core.cancel"))}>
                <p>{_.upperFirst(t("admin.invite.modalDeleteRowText"))}</p>
                {
                    userToRemove > -1 && users[userToRemove].email &&
                    <p>{_.upperFirst(t('admin.invite.modalDeleteRowTextEmail', {email:users[userToRemove].email}))}</p>
                }
                {
                    userToRemove > -1 && users[userToRemove].name &&
                    <p>{_.upperFirst(t("admin.invite.modalDeleteRowTextName", {name:users[userToRemove].name}))}</p>
                }
            </ConfirmModal>
            <ConfirmModal handleClose={() => {setShowModalPhone(false)}} show={showModalPhone} title={_.upperFirst(t("admin.invite.modalPhoneTitle"))} handleChange={() => {setShowModalPhone(false); setTimeout(() => {handleButton()}, 500)}} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.inviteUsers"))}>
                <p>{_.upperFirst(t("admin.invite.modalPhoneText"))}</p>
            </ConfirmModal>
        </div>
    )
}

InviteEditor.propTypes = {
    createdEmailsAndPhones:PropTypes.array.isRequired,
    usersTable:PropTypes.array.isRequired,
    isInvite:PropTypes.bool,
    handleButton:PropTypes.func.isRequired,
    buttonText:PropTypes.string.isRequired,
    handleChangeUsers:PropTypes.func.isRequired,
    reloadFilters:PropTypes.array,
    isBackoffice:PropTypes.bool,
}

InviteEditor.defaultProps = {
    isInvite:false,
    isBackoffice:false,
}
export default InviteEditor