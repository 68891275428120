import React, {useState, useEffect} from "react";
import PropTypes from "prop-types"
import {useTranslation} from "react-multi-lang/lib";
import Login from "../../../../../login/login";
import _ from "lodash";
import HttpApi from "../../../../../httpApi";
import {notifyError} from "../../../../../Utils/Notification";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import ListView from "../../../../Core/Modules/Views-Tables-Lists/ListView";
import UserModel from "../../../../../models/User";
import {FullContrastBlueTextField, Roles} from "../../../../../Utils/Global";
import {Divider, MenuItem} from "@mui/material";
import {Button} from "react-bootstrap";
import {useUserData} from "../../../../../hooks/userHook";

const WeBloomTeamPicker = (props) => {

    //state
    const [teams, setTeams] = useState([]);
    const [users, setUsers] = useState([]);

    const [sideFormOpen, setSideFormOpen] = useState(false);

    const {isDraft, team, targets, handleChange,isProspect} = props;

    //hooks
    const t = useTranslation();
    const userData = useUserData();

    //getters
    const fetchValidTeams = () => {
        let url = Login.hasRole("global_admin") ? "/teams/getFiltersAndTeamsWithUsers" : "/teams/getUserAdvancedCollabTeamsWithUsers";
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setTeams(response.data);
                if(!Login.hasRole("global_admin")){
                    if(response.data.find(el => el.id === 0) !== undefined){
                        setUsers(response.data.find(el => el.id === 0).users);
                    }else{
                        concatTeams(response.data);

                    }
                }
                if(isDraft){
                    if(response.data){
                        checkIfCompleteTeam(users, response.data);
                    }else{
                        handleChange(-2, null, "customized");
                    }
                }
            }else{
                setTeams([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }
    const fetchEmployees = (selectAll=false, lang=null) => {
        HttpApi.getV2("/users/getEmployees").then(response => {
            if(response && response.data){
                response.data.map(el => {return {id: el.id, displayName: el.displayName}});
                if(selectAll){
                    handleChange(-3, response.data, "allCurrentUsers");
                }else{
                    setUsers(response.data);
                }
            }else{
                setUsers([]);
            }
        })
    }

    //posters

    //handlers
    const handleChangeMenu = (index) => {
        if(team === -3 && index !== -3){
            handleChange(-3, [], "allCurrentUsers");
        }
        if(index === -2){
            if(Login.hasRole("global_admin")){
                fetchEmployees();
            }else if (Login.hasRole("global_manager") && teams.find(el => el.id === 0) !== undefined) {
                setUsers(teams.find(el => el.id === 0).users);
            }else{
                concatTeams();
            }
            setSideFormOpen(true);
        }else if(index >= 0){
            setUsers(teams[index].users);
            setSideFormOpen(true);
        }else if(index === -1){
            handleChange(-1, [], "allCurrentAndFutureUsers");
        }else if(index === -3){
            if(userData.roles.includes("global_manager") && teams.find(el => el.id === 0) !== undefined){
                handleChange(-3, teams.find(el => el.id === 0).users, "allCurrentUsers");
            }else if(userData.roles.includes("global_admin")){
                fetchEmployees(true);
            }

        }
    }

    const handleAdd = (item) => event => {
        event.preventDefault();
        let array = _.cloneDeep(targets);
        array.push(item);

        handleChange(null, array);

        checkIfCompleteTeam(array);

    }

    const handleRemove = (item) => event => {
        event.preventDefault();
        let array = _.cloneDeep(targets);
        const index = targets.indexOf(item);
        array.splice(index, 1);

        handleChange(null, array);

        checkIfCompleteTeam(array);
    }

    const handleAddAll = (items) => {
        //const toAdd = users.filter(el => !targets.some(e => e.id === el.id))
        const array = targets.concat(items);

        handleChange(null, array);

        checkIfCompleteTeam(array);
    }

    const handleRemoveAll = () => {
        handleChange(-4, [], "select");
    }

    const checkIfCompleteTeam = (array, t=null) => {
        let stop = false;
        const tms = t || teams;
        tms.forEach((team, index) => {
            if(!stop){
                if(team.users.length === array.length && team.users.filter(user => array.filter(el => el.id === user.id).length === 0).length === 0){
                    handleChange(index, null, teams[index].name);
                    stop = true;
                }
            }
        })
        if(!stop){
            if(array.length > 0){
                handleChange(-2, null, "customized");
            }else{
                handleChange(-4, null, "select");
            }
        }
    }

    const concatTeams = (ts = null) => {
        const teamsToConcat = ts || teams;
        let array = [];
        teamsToConcat.forEach(t => {
            if(t.id !== 0){
                array = array.concat(t.users);
            }
        })
        setUsers(array);
    }

    const toggleDrawer = (open) => event => {
        if(event){
            event.preventDefault();
        }
        setSideFormOpen(open);
    }
    //useEffect
    useEffect(() => {
        if(!userData.roles.includes(Roles.BACKOFFICE[0])){
            fetchValidTeams();
        }
    }, [])

    useEffect(() => {
        if(typeof team === "string" && teams && teams.length > 0){
            const index = teams.findIndex(el => el.name === team);
            handleChange(index, null, team, true);
        }
    }, [team, teams])

    return (
        <>
            <FullContrastBlueTextField size={"small"} select variant={"outlined"} value={team} onChange={() => {}}>
                <MenuItem key={-4} value={-4} disabled>{_.upperFirst(t("core.select"))}</MenuItem>
                {
                    (userData.roles.some(el => ["global_admin",Roles.BACKOFFICE[0]].includes(el)) || (userData.roles.includes("global_manager") && teams.find(el => el.id === 0) !== undefined) ) &&
                    <MenuItem key={-1} value={-1} onClick={() => {handleChangeMenu(-1)}}>{_.upperFirst(t("core.collaborative.allCurrentAndFutureUsers"))}</MenuItem>

                }
                {
                    (userData.roles.includes("global_admin") || (userData.roles.includes("global_manager") && teams.find(el => el.id === 0) !== undefined)) &&
                    <MenuItem key={-3} value={-3} onClick={() => {handleChangeMenu(-3)}}>{_.upperFirst(t("core.collaborative.allCurrentUsers"))}</MenuItem>

                }
                {
                    teams.filter(el => el.id !== 0).map((option, index) => {
                        let name = option.name
                        if(isProspect){
                           name = _.upperFirst(t("admin.team")) +" "+String.fromCharCode(index + 'A'.charCodeAt(0))
                        }
                       return <MenuItem key={index} disabled={isProspect}  value={index} onClick={() => {handleChangeMenu(index)}}>{name}</MenuItem>

                    })
                }
                <MenuItem key={-2} value={-2} disabled={isProspect} onClick={() => {handleChangeMenu(-2)}}>{_.upperFirst(t("core.customized"))}</MenuItem>
            </FullContrastBlueTextField>
            {
                team !== -3 && targets && targets.length > 0 &&
                <Button variant={"link"} className={"ebloom-link-blue"} onClick={() => {handleChangeMenu(team)}}>{_.upperFirst(t("core.edit"))}</Button>
            }

            <SideForm formTitle={"core.edit"} sideFormOpen={sideFormOpen} toggleDrawer={toggleDrawer} showButtons={false}>
                <div className={"flex"}>
                    <div className={"p-5 m-5"}>
                        <ListView model={UserModel} items={targets} handleAction={handleRemove} showSelectAll handleSelectAll={handleRemoveAll} showLangFilter actionType={"remove"}/>
                    </div>
                    <Divider orientation="vertical" flexItem/>
                    <div className={"p-5 m-5"}>
                        <ListView model={UserModel} items={users.filter(el => !targets.some(e => e.id === el.id))} handleAction={handleAdd} showSelectAll handleSelectAll={handleAddAll} showLangFilter/>
                    </div>
                </div>
            </SideForm>
        </>
    )
}

WeBloomTeamPicker.propTypes = {
    isDraft:PropTypes.bool,
    handleChange:PropTypes.func.isRequired,
    isProspect : PropTypes.bool
}

WeBloomTeamPicker.defaultProps = {
    isDraft:false,
    isProspect:false
}
export default WeBloomTeamPicker