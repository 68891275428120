import React, { useEffect, useRef, useState } from 'react';
import {SurveyStatus, PollTypes} from "../Modules/Types";
import PollFrame from "../Modules/PollFrame";
import Choice from "./Modules/Choice";
import StarRating from "./Modules/StarRating";
import OpenQuestion from "./Modules/OpenQuestion";
import Name from "./Modules/Name";
import {useLocation, useParams} from "react-router-dom";
import moment from "moment";
import LoadingView from "../../../../../Core/Layouts/LoadingView";
import _ from "lodash";
import {ListGroup} from "react-bootstrap";
import EbloomPopover from "../../../../../Core/Modules/Popovers-Tooltips/EbloomPopover";
import {useTranslation} from "react-multi-lang/lib";
import HttpApi from "../../../../../../httpApi";
import {notifyError, notifySuccess} from "../../../../../../Utils/Notification";
import fileSaver from "file-saver/dist/FileSaver";
import jsPDF from "jspdf";
import {
    displayDate,
    FullContrastPurpleTextField,
    Limit,
    processImageForPdf, replaceUrlsWithLinks
} from "../../../../../../Utils/Global";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import FileDisplay from "../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {getTranslations} from "../../../../../../lang/translations";
import {getLanguage} from "react-multi-lang";
import {styled} from "@mui/material/styles";
import {toCanvas, toPng} from "html-to-image";
import ResultFrame from "./Modules/ResultFrame";
import {useUserData} from "../../../../../../hooks/userHook";
import ProspectModal from "../../../../../Core/Modules/Modal/ProspectModal";


const TextFieldStyled = styled(FullContrastPurpleTextField)(({theme}) => ({
    '& .MuiInputBase-input': {
        textAlign:"center",
        fontSize:"20px",
        fontFamily:"Lato",
        padding:0
    },
}))

const WeBloomResultPoll = () => {

    //state
    const [survey, setSurvey] = useState(null);
    const [individualResults, setIndividualResults] = useState([]);
    const [activeIndividualResult, setActiveIndividualResult] = useState(0);
    const [displayedActiveIndividualResult, setDisplayedActiveIndividualResult] = useState(1);
    const [teamToDisplay, setTeamToDisplay] = useState(0);

    const [showGraph,setShowGraph] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [loadingIndividualResults, setLoadingIndividualResults] = useState(false);
    const [loading, setLoading] = useState(false);
    const [individualView, setIndividualView] = useState(false)
    const [showModalProspect, setShowModalProspect] = useState(false);

    const [isGroup, setIsGroup] = useState(false);
    const [entity, setEntity] = useState(null);

    //hooks
    const location = useLocation();
    const t = useTranslation();
    const userData = useUserData();
    const params = useParams()

    const targetRef = useRef()

    const isProspect = userData.module === "prospect"

    //getters
    const getIndividualResults = (index=0) => {
        if(!loading && (Boolean(survey?.is_anonymous) === true && survey?.nbResponses >= Limit.ANONYMITY) || (Boolean(survey?.is_anonymous) === false && survey?.nbResponses >=1)){
            setLoading(true)
            let urlIndividual = "/webloom/getSurveyIndividualResults/" + survey.id + "/" + teamToDisplay
            if(entity !== null){
                if(isGroup){
                    urlIndividual = "/webloom/group/getSurveyIndividualResults/" + survey.id + "/"+ entity +"/"+ teamToDisplay
                }else{
                    urlIndividual = "/webloom/entities/getSurveyIndividualResults/" + survey.id + "/"+ entity +"/"+ teamToDisplay
                }
            }
            urlIndividual += '/' + index;
            HttpApi.getV2(urlIndividual).then(response => {
                if(response && response.data){
                    setIndividualResults(response.data);
                }else{
                    setIndividualResults([]);
                }
                setLoading(false);
            }).catch(error => notifyError(error)).finally(() => {
                setLoadingIndividualResults(false)
                setLoading(false);
            });
        }else{
            if(individualView){
                setIndividualResults([]);
                setIndividualView(false);
            }
            setLoading(false);
        }
    }

    //posters
    const exportSurvey = () => {
        setLoadingExcel(true);
        let url = "/webloom/export/" + survey.id + "/" + teamToDisplay
        if(entity){
            if (isGroup){
                url = "/webloom/group/export/" + survey.id + "/" + entity + "/"+ teamToDisplay
            }else{
                url = "/webloom/entities/export/" + survey.id + "/" + entity + "/"+ teamToDisplay
            }
        }
        HttpApi.getWithResponseTypeV2(url).then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileSaver.saveAs(blob, 'webloom-survey.xlsx');
            notifySuccess(_.upperFirst(t("webloom.notification.pollExported")));
        }).catch(error => {
            notifyError(error);
        }).finally(() => {
            setLoadingExcel(false);
        });
    }

    const filter = (node) => {
        const exclusionClasses = ['report-hide', 'crisp-client'];
        return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    }

    const disableClicks = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const exportPdf = async () => {
        try{
            if(!loadingPdf){
                document.addEventListener("click", disableClicks, true);
                setLoadingPdf(true);

                let pdf = new jsPDF({orientation:"portrait", compress:true, format:'A4'});
                const width = pdf.internal.pageSize.getWidth();
                const height = pdf.internal.pageSize.getHeight();
                const report1 = require("../../../../../Core/symbols/reports/report_start_portrait_" + survey.lang + ".png");
                pdf.addImage(report1, "PNG", 0, 0, width, height, null, "SLOW");
                let nodes = document.querySelectorAll(".report-webloom");
                let baseNode = document.getElementById("report-baseline");
                for(let i = 0; i<nodes.length; i++){
                    let el = nodes[i];
                    let shouldHide = false;
                    if(nodes[i].style.display === "none"){
                        shouldHide = true;
                        nodes[i].style.display = "block";
                    }
                    let canvaWidth = 0;
                    let canvaHeight = 0;
                    let nodeToRemove = null;
                    if(i>0){
                        el = document.createElement("div");
                        baseNode.appendChild(el);
                        let node0 = nodes[i].cloneNode(true);
                        el.appendChild(node0);
                        if(i+1 < nodes.length){
                            let node1 = nodes[i+1].cloneNode(true);
                            el.appendChild(node1);
                            if(i+2 < nodes.length){
                                let node2 = nodes[i+2].cloneNode(true);
                                el.appendChild(node2);
                                if(i+3 < nodes.length){
                                    let node3 = nodes[i+3].cloneNode(true);
                                    el.appendChild(node3);
                                    i+=3;
                                }else{
                                    i+=2
                                }
                            }else{
                                i++;
                            }
                        }
                        nodeToRemove = el;
                    }

                    let canva = await toCanvas(el, {filter:filter, skipFonts:true});
                    canvaWidth = canva.width;
                    canvaHeight = canva.height;

                    await toPng(el, {style:{backgroundColor:"#EFF9FE"}, filter:filter, skipFonts:true}).then(async (dataUrl) => {
                        if(nodeToRemove !== null){
                            baseNode.removeChild(nodeToRemove);
                        }
                        await processImageForPdf(pdf, dataUrl, canvaWidth, canvaHeight).then(newPdf => {
                            pdf = newPdf;
                            if(shouldHide){
                                nodes[i].style.display = "none";
                            }
                            if(i === nodes.length-1){
                                let name = getTranslations(getLanguage(), "manager.dashboard.report.report");
                                name += "_" + survey.title + ".pdf";
                                pdf.addPage("A4", "portrait")
                                const report10 = require("../../../../../Core/symbols/reports/report_end_portrait_" + survey.lang + ".png");
                                pdf.addImage(report10, 0, 0, width, height, null, "SLOW");
                                pdf.save(name)
                                document.removeEventListener("click", disableClicks, true);
                                setLoadingPdf(false);
                            }
                        }).catch(error => {
                            console.log(error);
                        })
                    }).catch(error => {
                        console.log(error);
                    })
                }
            }
        }catch(error){
            console.log(error)
            notifyError(error);
            setLoadingPdf(false);
        }

    }

    //handlers
    const handleShowGraph = () => {
        setShowGraph(!showGraph);
    }

    const handleResultView = () => {
        if(!individualView){
            getIndividualResults();
        }
        setIndividualView(!individualView);

    }

    const handleActiveResult = (event) => {
        const value = event.target.value;
        if(value >= 1 && value <= survey.nbResponses){
            setDisplayedActiveIndividualResult(value);
            setActiveIndividualResult(value-1);
            getIndividualResults(value-1);
        }else if(!value && value !== 0){
            setDisplayedActiveIndividualResult(value);
        }
    }

    const handleMarkAsRead = (q) => {
        setSurvey(prevState => {
            let survey = {...prevState};
            let question = survey.questions.find(el => el.id === q.id);
            question.answers = q.answers;
            return survey;

        })
    }

    const handleTeamToDisplay = (event) => {
        setTeamToDisplay(event.target.value)
        setDisplayedActiveIndividualResult(1);
        if(individualView){
            setActiveIndividualResult(0);
        }
    }

    //useEffect
    useEffect(() => {
        let idEntity = null
        let isGroupValue = false

        if(params.idGroup){
            idEntity = parseInt(params.idGroup);
            isGroupValue = idEntity === userData.companyGroup.id
            setIsGroup(isGroupValue)
            setEntity(idEntity)
        }
        if((survey && survey.id >=0) || (location && location.state)){
            let id = survey && survey.id >= 0 ? survey.id : location.state.id;
            let url = "/webloom/getSurveyTeamResults/" + id + "/" + teamToDisplay
            if(idEntity !== null){
                if (isGroupValue){
                    url = "/webloom/group/getSurveyTeamResults/"+ id + "/"+idEntity + "/"+ teamToDisplay
                }else{
                    url = "/webloom/entities/getSurveyTeamResults/"+ id + "/"+idEntity + "/"+ teamToDisplay
                }
            }
            HttpApi.getV2(url).then(response => {
                setLoading(true);
                if(response && response.data){
                    setSurvey(response.data);
                    /*if((Boolean(response.data.is_anonymous) === true && response.data.nbResponses >= Limit.ANONYMITY) || (Boolean(response.data.is_anonymous) === false && response.data.nbResponses >=1)){
                        let urlIndividual = "/webloom/getSurveyIndividualResults/" + id + "/" + teamToDisplay
                        if(idEntity !== null){
                            if(isGroupValue){
                                urlIndividual = "/webloom/group/getSurveyIndividualResults/" + id + "/"+ idEntity +"/"+ teamToDisplay
                            }else{
                                urlIndividual = "/webloom/entities/getSurveyIndividualResults/" + id + "/"+ idEntity +"/"+ teamToDisplay
                            }
                        }
                        HttpApi.getV2(urlIndividual).then(response => {
                            if(response && response.data){
                                setIndividualResults(response.data);
                            }else{
                                setIndividualResults([]);
                            }
                        }).catch(error => notifyError(error)).finally(() => {
                            setLoadingIndividualResults(false)
                            setLoading(false);
                        });
                    }else{
                        if(individualView){
                            setIndividualResults([]);
                            setIndividualView(false);
                        }
                        setLoading(false);
                    }*/
                    setLoading(false);
                }
            }).catch(error =>{
                notifyError(error)
                setLoading(false);
            });
        }
    }, [teamToDisplay]);

    return(
        <PollFrame status={SurveyStatus.DONE} anonymityRestriction={!survey ? true : survey.is_anonymous ? survey.nbResponses < Limit.ANONYMITY : survey.nbResponses < 1} noData={!survey || survey.nbResponses < 1} handleShowGraph={handleShowGraph} showGraph={showGraph} handleExportExcel={exportSurvey} handleExportPdf={exportPdf} loadingExcel={loadingExcel} loadingPdf={loadingPdf} handleResultView={handleResultView} isIndividualView={individualView} loadingIndividualResults={loadingIndividualResults} handleTeamToDisplay={handleTeamToDisplay} teamToDisplay={teamToDisplay} entity={entity ? entity : -1} isGroup={isGroup}>
            {
                (survey && !loading) ?
                <div className={"mb-20 cursor-default"}>
                    <div className={"grid grid-cols-3 grid-rows-1 text-contrast-purple"}>
                        <span>{displayDate(survey.start_date || survey.created_at, true) + " - " + (survey.end_date ? displayDate(survey.end_date, true) : _.upperFirst(t("webloom.noEndDefined")))}</span>
                        <div className={"justify-self-center"}>
                            {
                                isProspect ?
                                    <div>
                                        <span style={{fontSize:18}} className={"text-line-break"}>#{survey.recipients === "customized" ? _.upperFirst(t("core.customized")) : survey.recipients === "allCurrentUsers" ? _.upperFirst(t("core.collaborative.allCurrentUsers")) + "\n(" + moment(survey.created_at).format("DD/MM/YYYY") + ")" : survey.recipients === "allCurrentAndFutureUsers" ? _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers")) : isProspect ?  _.upperFirst(t("core.customized")) :survey.recipients}</span>
                                    </div>
                                    :
                                    <EbloomPopover  isCollab={true} triggerText={survey.recipients === "customized" ? _.upperFirst(t("core.customized")) : survey.recipients === "allCurrentUsers" ? _.upperFirst(t("core.collaborative.allCurrentUsers")) + "\n(" + moment(survey.created_at).format("DD/MM/YYYY") + ")" : survey.recipients === "allCurrentAndFutureUsers" ? _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers")) : survey.recipients} placement={"bottom"} triggerVariant={"link"}>
                                        {
                                            () => (
                                                <ListGroup variant={"flush"}
                                                           style={{maxHeight: 250, overflowY: "auto", overflowX: "hidden"}}>
                                                    {
                                                        survey.users.map((user, index) => (
                                                            <ListGroup.Item key={index} style={{
                                                                padding: 0,
                                                                border: "none"
                                                            }}>{user.email}</ListGroup.Item>
                                                        ))
                                                    }
                                                </ListGroup>
                                            )
                                        }
                                    </EbloomPopover>
                            }
                        </div>
                        <div className={"justify-self-end"}><span>{survey.nbResponses}</span>{" " + t("webloom.participants") + " (max " + survey.users.length +")"}</div>
                    </div>
                    <div className={"w-3/5 m-auto mb-20 cursor-default"}>
                        {
                            individualView &&
                            <div className={"flex items-center justify-center mt-20"}>
                                <div><KeyboardArrowLeftIcon className={"text-purple " + (activeIndividualResult === 0 ? "invisible" : "visible pointer")} onClick={() => {setDisplayedActiveIndividualResult(activeIndividualResult); setActiveIndividualResult(activeIndividualResult-1); getIndividualResults(activeIndividualResult-1)}}/></div>
                                <span className={"ebloom-text ebloom-very-large-text text-contrast-purple"}>{_.upperFirst(t("core.answer"))}<TextFieldStyled disabled={loadingPdf} className={"w-30"} variant="standard" size={"small"} value={displayedActiveIndividualResult} onChange={handleActiveResult}/>{"/" + survey.nbResponses}</span>
                                <div><KeyboardArrowRightIcon className={"text-purple " + (activeIndividualResult === survey.nbResponses-1 ? "invisible" : "visible pointer")} onClick={() => {setDisplayedActiveIndividualResult(activeIndividualResult+2);setActiveIndividualResult(activeIndividualResult+1); getIndividualResults(activeIndividualResult+1)}}/></div>
                            </div>
                        }
                        <div>
                            <div className={"ebloom-title ebloom-small-title text-contrast-purple mt-20 font-semibold"}>{survey.title ? replaceUrlsWithLinks(survey.title): survey.title}</div>
                            <div className={"ebloom-normal-text text-contrast-purple mt-20 mb-30 text-line-break"}>{survey.description ? replaceUrlsWithLinks(survey.description): survey.description}</div>
                            {
                                survey.image_url &&
                                <FileDisplay className={"w-fit"} urlParams={survey.image_url} displayImg imgHeight={400}/>
                            }
                            {
                                survey.attachment_url &&
                                <FileDisplay className={"my-10"} urlParams={survey.attachment_url}/>
                            }
                        </div>
                        <div className={"w-full m-auto report-webloom border border-white-blue"} style={{display:"none"}}>
                            <div className={"text-center ebloom-title ebloom-normal-title text-contrast-purple mt-20 font-semibold"}>{survey.title ? replaceUrlsWithLinks(survey.title): survey.title}</div>
                            <div className={"w-fit m-auto"}>
                                <div className={"ebloom-xtra-large-text my-10 text-contrast-purple flex items-center justify-between"}><div>{_.upperFirst(getTranslations(survey.lang, "core.start"))}</div><div className={"ml-20 font-semibold"}>{moment(survey.start_date || survey.created_at).format("DD/MM/YY")}</div></div>
                                {
                                    survey.end_date &&
                                    <div className={"ebloom-xtra-large-text my-10 text-contrast-purple flex items-center justify-between"}><div>{_.upperFirst(getTranslations(survey.lang, "core.end"))}</div><div className={"ml-20 font-semibold"}>{moment(survey.end_date).format("DD/MM/YY")}</div></div>
                                }
                                <div className={"ebloom-xtra-large-text my-10 text-contrast-purple flex items-center justify-between"}><div>{_.upperFirst(getTranslations(survey.lang, "webloom.participants"))}</div><div className={"ml-20 font-semibold"}>{survey.nbResponses}</div></div>
                            </div>
                            <div className={"text-center ebloom-large-text text-contrast-purple mt-20 mb-30 text-line-break"}>{survey.description ? replaceUrlsWithLinks(survey.description): survey.description}</div>
                            {
                                survey.image_url &&
                                <FileDisplay urlParams={survey.image_url} displayImg imgHeight={400}/>
                            }
                        </div>
                        <div id={"report-baseline"} ref={targetRef}>
                            {
                                !individualView ?
                                    survey.questions.map((question, index) => (
                                        question.type === PollTypes.STAR_RATING ?
                                            <StarRating question={question} key={index} showAllGraph={showGraph} anonymityRestriction={survey.is_anonymous ? survey.nbResponses<Limit.ANONYMITY : survey.nbResponses<1} noData={survey.nbResponses<1} setShowModalProspect={setShowModalProspect} isProspect={isProspect}/>
                                            :
                                            question.type === PollTypes.OPEN_QUESTION ?
                                                <OpenQuestion question={question} key={index} handleMarkAsRead={handleMarkAsRead} anonymityRestriction={survey.is_anonymous ? survey.nbResponses<Limit.ANONYMITY : survey.nbResponses<1} noData={survey.nbResponses<1} isIndividualView={individualView} setShowModalProspect={setShowModalProspect} isProspect={isProspect}/>
                                                :
                                                (question.type === PollTypes.NAME || question.type === PollTypes.IDENTIFICATION) ?
                                                    <Name question={question} key={index} anonymityRestriction={survey.is_anonymous ? survey.nbResponses<Limit.ANONYMITY : survey.nbResponses<1} isIndividualView={individualView} noData={survey.nbResponses<1} setShowModalProspect={setShowModalProspect} isProspect={isProspect}/>
                                                    :
                                                    question.type === PollTypes.TITLE ?
                                                        <ResultFrame question={question} anonymityRestriction={false} lang={survey.lang} setShowModalProspect={setShowModalProspect} isProspect={isProspect}/>
                                                        :
                                                <Choice key={index} question={question} showAllGraph={showGraph} lang={survey.lang} anonymityRestriction={survey.is_anonymous ? survey.nbResponses<Limit.ANONYMITY : survey.nbResponses<1} noData={survey.nbResponses<1} setShowModalProspect={setShowModalProspect} isProspect={isProspect}/>
                                    ))
                                    ://individual results => display according to index
                                    individualResults[0]?.map((question, index) => (
                                        question.type === PollTypes.STAR_RATING ?
                                            <StarRating question={question} key={index} showAllGraph={showGraph} anonymityRestriction={survey.is_anonymous ? survey.nbResponses<Limit.ANONYMITY : survey.nbResponses<1} noData={survey.nbResponses<1} setShowModalProspect={setShowModalProspect} isProspect={isProspect}/>
                                            :
                                            question.type === PollTypes.OPEN_QUESTION ?
                                                <OpenQuestion question={question} key={index} handleMarkAsRead={handleMarkAsRead} anonymityRestriction={survey.is_anonymous ? survey.nbResponses<Limit.ANONYMITY : survey.nbResponses<1} noData={survey.nbResponses<1} isIndividualView={individualView} setShowModalProspect={setShowModalProspect} isProspect={isProspect}/>
                                                :
                                                (question.type === PollTypes.NAME || question.type === PollTypes.IDENTIFICATION) ?
                                                    <Name question={question} key={index} anonymityRestriction={survey.is_anonymous ? survey.nbResponses<Limit.ANONYMITY : survey.nbResponses<1} isIndividualView={individualView} noData={survey.nbResponses<1} setShowModalProspect={setShowModalProspect} isProspect={isProspect}/>
                                                    :
                                                    question.type === PollTypes.TITLE ?
                                                        <ResultFrame question={question} anonymityRestriction={false} lang={survey.lang} setShowModalProspect={setShowModalProspect} isProspect={isProspect}/>
                                                        :
                                                <Choice question={question} key={index} showAllGraph={showGraph} lang={survey.lang} anonymityRestriction={survey.is_anonymous ? survey.nbResponses<Limit.ANONYMITY : survey.nbResponses<1} noData={survey.nbResponses<1} setShowModalProspect={setShowModalProspect} isProspect={isProspect}/>
                                    ))
                            }
                        </div>
                    </div>
                </div>
                    :
                    <LoadingView/>
            }
            <ProspectModal show={showModalProspect} handleClose={() => setShowModalProspect(false)}/>
        </PollFrame>

    )
}

export default WeBloomResultPoll;