import HeaderAdmin from './HeaderAdmin'
import NavDrawerAdmin from "./NavDrawerAdmin";
import React, {useEffect, useState} from "react";
import {Colors} from "../../../Utils/Colors";
import Login from "../../../login/login";
import HttpApi from "../../../httpApi";
import {notifyError} from "../../../Utils/Notification";

const DisplayAdminView = (props) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [nbActiveSurvey, setNbActiveSurvey] = useState(0);
    const [nbAnswersSurvey, setNbAnswersSurvey] = useState(0);
    const [nbSurvey, setNbSurvey] = useState(0);
    const [collabAccess, setCollabAccess] = useState(false);
    const [menuName, setMenuName] = useState("noMenu");

    const {menu, children, bp} = props;

    const drawMargin = (bp.pageSize > bp.breakpoints.tablet && menuName !== "noMenu") ? 240 : 0;

    const isAdmin = Login.hasRole("global_admin");
    const isFree = Login.hasModule("free");

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const checkIfCollabAccess = () => {
        if (Login.hasRole("global_manager")) {
            HttpApi.getV2("/teams/getUserAdvancedCollabTeamsWithUsers").then(result => {
                if (result && result.data && result.data.length > 0) {
                    setCollabAccess(true);
                    checkForCollabSurveyAnswers();
                } else {
                    setCollabAccess(false);
                    if (menu === "collaborative") {
                        setMenuName("noMenu");
                    }
                }
            }).catch(error => {
                notifyError(error);
                setCollabAccess(false);
            })
        } else if (isAdmin) {
            checkForCollabSurveyAnswers();
        }
    }

    const checkForCollabSurveyAnswers = () => {
        const url = "/collaborative/checkForNewCollabAnswers";
        HttpApi.get(url).then(response => {
            if (response && response.data) {
                setNbAnswersSurvey(response.data);
            } else {
                setNbAnswersSurvey(0);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const checkForCollabSurvey = () => {
        const url = "/collaborative/checkForActiveSurveyUser";
        HttpApi.get(url).then(response => {
            if (response && response.data) {
                const surveys = response.data;
                setNbActiveSurvey(surveys && surveys.activeSurveys ? surveys.activeSurveys.length : 0);
                setNbSurvey(surveys.nbSurveys);
            } else {
                setNbActiveSurvey(0);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    useEffect(() => {
        setMenuName(menu);
    }, [menu])

    return (
        <div className={"bg-white-blue min-h-screen"}>
            <div className={" mr-0"} style={{marginLeft: drawMargin}}>
                {
                    menuName !== "noMenu" &&
                    <NavDrawerAdmin mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}
                                    isFree={isFree} {...props}/>
                }
                <HeaderAdmin handleDrawerToggle={handleDrawerToggle} hasMenu={menuName !== "noMenu"}
                             nbActiveSurvey={nbActiveSurvey} nbAnswersSurvey={nbAnswersSurvey} nbSurvey={nbSurvey}
                             checkForCollabSurvey={checkForCollabSurvey} checkIfCollabAccess={checkIfCollabAccess}
                             collabAccess={collabAccess} isFree={isFree} menu={menuName} {...props}/>
            </div>
            <div className={"pt-114"} style={{width: `calc(100% - ${drawMargin}px)`, marginLeft: drawMargin}}>
                {children(checkForCollabSurvey, checkIfCollabAccess, nbActiveSurvey)}
            </div>
            <div className={"h-20"}/>
        </div>
    )
}

DisplayAdminView.defaultProps = {
    backgroundColor: Colors.EBLOOM_WHITE_BLUE,

};

export default DisplayAdminView;
