
export const planClickAndBloomSteps = [
    {
        title: "admin.productTour.planClickAndBloom.step1.title",
        content: "admin.productTour.planClickAndBloom.step1.content",
        target: '[data-planCB="plus"]',
        placement: 'auto',
        disableBeacon : true,
        spotlightClicks: false
    },
    {
        title: "admin.productTour.planClickAndBloom.step2.title",
        content: "admin.productTour.planClickAndBloom.step2.content",
        target: '[data-planCB="navigate"]',
        placement: 'right',
        disableBeacon : true,
        spotlightClicks: true
    },
    {
        title: "admin.productTour.planClickAndBloom.step3.title",
        content: "admin.productTour.planClickAndBloom.step3.content",
        target: '[data-planCB="clearPlanner"]',
        placement: 'left',
        disableBeacon : true,
        spotlightClicks: false
    },
    {
        title: "admin.productTour.planClickAndBloom.step4.title",
        content: "admin.productTour.planClickAndBloom.step4.content",
        target: '[data-planCB="autoPlanner"]',
        placement: 'right',
        disableBeacon : true,
        spotlightClicks: false
    },
    {
        title: "admin.productTour.planClickAndBloom.step5.title",
        content: "admin.productTour.planClickAndBloom.step5.content",
        target: '[data-planCB="selectPlaylist"]',
        placement: 'right',
        disableBeacon : true,
        spotlightClicks: false

    },
    {
        title: "admin.productTour.planClickAndBloom.step6.title",
        content: "admin.productTour.planClickAndBloom.step6.content",
        target: '[data-planCB="changeFrequency"]',
        placement: 'right',
        disableBeacon : true,
        spotlightClicks : false
    },
    {
        title: "admin.productTour.planClickAndBloom.step7.title",
        content: "admin.productTour.planClickAndBloom.step7.content",
        target: '[data-planCB="switchAutoPlanner"]',
        placement: 'right',
        disableBeacon : true,
        spotlightClicks: false

    },

]