import React, {useState, useEffect} from "react";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import EbloomSelectLang from "../../../Core/Modules/Input/EbloomSelectLang";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import {getLanguage} from "react-multi-lang";
import {getTranslations} from "../../../../lang/translations";
import {useUserData} from "../../../../hooks/userHook";
import HttpApi from "../../../../httpApi";
import {notifyError} from "../../../../Utils/Notification";

const OtherChanelCom = (props) => {

    //state
    const [inviteLang, setInviteLang] = useState(getLanguage());
    const [companyCode, setCompanyCode] = useState("");


    //props
    const {handleBack} = props;

    //hooks
    const t = useTranslation();
    const user = useUserData();

    const com2 = getTranslations(inviteLang, "admin.com2", {company:user.company, companyCode:companyCode});

    //getters

    //posters

    //handlers

    //useEffect

    useEffect(() => {
        HttpApi.getV2("/companies/getCompanyCode").then(response => {
            if(response && response.data){
                setCompanyCode(response.data.code);
            }else{
                setCompanyCode("");
            }
        }).catch(error => {
            notifyError(error);
        });
    }, [])

    return (
        <>
            <CardDefault title={_.upperFirst(t("core.inviteUsers"))} showButton={false} showLeftButton={true} handleLeftButton={handleBack} leftButtonText={_.upperFirst(t("core.back"))} leftButtonVariant={"secondary"}>
                <div className={"w-full text-justify"} >
                    <p className={"whitespace-pre"}>{_.upperFirst(t("admin.inviteUserHelper", {companyCode:companyCode}))}</p>
                    <br/>
                    <p>{_.upperFirst(t("admin.inviteUserHelper2"))}</p>
                </div>
                <div className={"ebloom-text ebloom-large-text font-semibold mt-10 mb-5"}>{_.upperFirst(t("admin.launchComProposition"))}</div>
                <p>{_.upperFirst(t("admin.launchComPropositionHelper"))}</p>
                <div className={"my-10"}>
                    <EbloomSelectLang handleChange={(event) => {setInviteLang(event.target.value)}} value={inviteLang}/>
                </div>
                <CardDefault showHeader={false} border={"1px solid #C4C4C4"}>
                    <p className={"text-line-break"}>{com2}</p>
                </CardDefault>
            </CardDefault>
        </>
    )
}

OtherChanelCom.propTypes = {}

OtherChanelCom.defaultProps = {}
export default OtherChanelCom