import React from "react";
import DisplayAdminView from "../../../Layouts/DisplayBackofficeView";
import {Container} from "react-bootstrap";
import Frequency from "../../../../Admin/Pages/Frequency/Frequency";



const BackofficeFrequencyPage = (props) =>{

    return (
        <DisplayAdminView {...props}>
            {companyToEdit => (
                <Container>
                    <Frequency companyToEdit={companyToEdit}/>
                </Container>
            )
            }
        </DisplayAdminView>

    )
};

export default BackofficeFrequencyPage


