import React from "react";
import Login from "../login/login";
import {Roles, Modules} from "../Utils/Global";
import PublicRoute from "./PublicRoute";
import LoginPage from "../Components/Core/LoginPage";
import ResetPassword from "../Components/Core/ResetPassword";
import {Route, Routes, Navigate} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ManagerDashboardPage from "../Components/Manager/Pages/ManagerDashboardPage";
import ProfilePage from "../Components/Core/ProfilePage";
import EmployeeDashboardPage from "../Components/Employee/Pages/MyBloom/EmployeeDashboardPage";
import CompanyEditorPage from "../Components/Backoffice/Pages/Global/Company/CompanyEditorPage";
import UserEditorPage from "../Components/Backoffice/Pages/Global/User/UserEditorPage";
import QuestionEditorPage from "../Components/Backoffice/Pages/Global/Question/QuestionEditorPage";
import CategoryEditorPage from "../Components/Backoffice/Pages/Global/Category/CategoryEditorPage";
import DailyEditorPage from "../Components/Backoffice/Pages/Company/Daily/DailyEditorPage";
import AdminDashboardPage from "../Components/Backoffice/Pages/Company/Dashboard/AdminDashboardPage";
import BackofficeEmployeeEditorPage from "../Components/Backoffice/Pages/Company/Employee/BackofficeEmployeeEditorPage";
import MessagePage from "../Components/Backoffice/Pages/Global/Message/MessagePage";
import TagEditorPage from "../Components/Backoffice/Pages/Global/Tag/TagEditorPage";
import PlaylistEditorPage from "../Components/Backoffice/Pages/Global/Playlist/PlaylistEditorPage";
import NotificationEditorPage from "../Components/Backoffice/Pages/Company/Notification/NotificationEditorPage";
import EmployeeEditorPage from "../Components/Admin/Pages/Employee/EmployeeEditorPage";
import IntegrationPage from "../Components/Admin/Pages/Integration/IntegrationPage";
import FrequencyPage from "../Components/Admin/Pages/Frequency/FrequencyPage";
import LibraryPage from "../Components/Admin/Pages/Questions/Library/LibraryPage";
import PlaylistPage from "../Components/Admin/Pages/Questions/Playlist/PlaylistPage";
import PlaylistListPage from "../Components/Admin/Pages/Questions/Playlist/PlaylistListPage";
import PlannerPage from "../Components/Admin/Pages/Questions/Planner/PlannerPage";
import TeamsPage from "../Components/Admin/Pages/Team/TeamsPage";
import TeamPage from "../Components/Admin/Pages/Team/TeamPage";
import LogPage from "../Components/Backoffice/Pages/Global/Log/LogPage";
import SubmitCustomPage from "../Components/Admin/Pages/Questions/Custom/SubmitCustomPage";
import SettingPage from "../Components/Backoffice/Pages/Company/Setting/SettingPage";
import TeamViewPage from "../Components/Backoffice/Pages/Company/Team/TeamViewPage";
import PlaylistViewPage from "../Components/Backoffice/Pages/Company/Playlist/PlaylistViewPage";
import TeamView from "../Components/Backoffice/Pages/Company/Team/TeamView";
import PlaylistView from "../Components/Backoffice/Pages/Company/Playlist/PlaylistView";
import StatisticPage from "../Components/Backoffice/Pages/Global/Statistic/StatisticPage";
import TeamsConfig from "../Components/Core/TeamsConfig";
import DailySurveyPage from "../Components/Employee/Pages/Daily/DailySurveyPage";
import TestPage from "../Components/Core/Layouts/TestPage";
import PlannerEditorPage from "../Components/Backoffice/Pages/Global/Planner/PlannerEditorPage";
import NotificationPage from "../Components/Backoffice/Pages/Global/Notification/NotificationPage";
import CommunicationPage from "../Components/Admin/Pages/Communication/CommunicationPage";
import ModulePage from "../Components/Backoffice/Pages/Global/Module/ModulePage";
import BackofficeReport from "../Components/Backoffice/Pages/Company/Report/BackofficeReport";
import IdentityPage from "../Components/Admin/Pages/Identity/IdentityPage";
import BackofficeAccountPage from "../Components/Backoffice/Pages/Company/Account/BackofficeAccountPage";
import GlobalDashboardPage from "../Components/Backoffice/Pages/Global/Dashboard/GlobalDashboardPage";
import WeBloomDashboardPage from "../Components/Admin/Pages/WeBloom/Dashboard/WeBloomDashboardPage";
import WeBloomCreatePollPage from "../Components/Admin/Pages/WeBloom/Polls/Create/WeBloomCreatePollPage";
import WeBloomAnswerPollPage from "../Components/Employee/Pages/WeBloom/Polls/Answer/WeBloomAnswerPollPage";
import WeBloomResultPollPage from "../Components/Admin/Pages/WeBloom/Polls/Results/WeBloomResultPollPage";
import WeBloomAnswerDashboardPage from "../Components/Employee/Pages/WeBloom/Dashboard/WeBloomAnswerDashboardPage";
import WeBloomCreateIdeaboxPage from "../Components/Admin/Pages/WeBloom/Ideabox/Create/WeBloomCreateIdeaboxPage";
import WeBloomCreateMessagePage from "../Components/Admin/Pages/WeBloom/Messages/Create/WeBloomCreateMessagePage";
import WeBloomAnswerIdeaboxPage from "../Components/Employee/Pages/WeBloom/Ideabox/Answer/WeBloomAnswerIdeaboxPage";
import WeBloomResultIdeaboxPage from "../Components/Admin/Pages/WeBloom/Ideabox/Results/WeBloomResultIdeaboxPage";
import BackofficeWeBloomPage from "../Components/Backoffice/Pages/Company/WeBloom/BackofficeWeBloomPage";
import WeBloomResultMessagePage from "../Components/Admin/Pages/WeBloom/Messages/Results/WeBloomResultMessagePage";
import WeBloomTemplatePage from "../Components/Backoffice/Pages/Global/WeBloom/WeBloomTemplatePage";
import FAQEditorPage from "../Components/Backoffice/Pages/Global/FAQ/FAQEditorPage";
import FAQPage from "../Components/Admin/Pages/FAQ/FAQPage";
import FilterEditorPage from "../Components/Backoffice/Pages/Company/Filter/FilterEditorPage";
import AccountPage from "../Components/Admin/Pages/Account/AccountPage";
import PublicDailySurveyPage from "../Components/Employee/Pages/Daily/PublicDailySurveyPage";
import BackofficeIdentityPage from "../Components/Backoffice/Pages/Company/Identity/BackofficeIdentityPage";
import BackofficeFrequencyPage from "../Components/Backoffice/Pages/Company/Frequency/BackofficeFrequencyPage";
import BackofficeLibraryPage from "../Components/Backoffice/Pages/Company/Library/BackofficeLibraryPage";
import CreateAccountPage from "../Components/Core/CreateAccountPage";
import BackofficeCustomCategoriesPage from "../Components/Backoffice/Pages/Company/Categories/BackofficeCustomCategoriesPage";
import CustomCategoriesPage from "../Components/Admin/Pages/Categories/CustomCategoriesPage";
import MobileEditorPage from "../Components/Backoffice/Pages/Global/Mobile/MobileEditorPage";
import IntegrationEditorPage from "../Components/Backoffice/Pages/Company/Integration/IntegrationEditorPage";
import WeBloomResultAlertPage from "../Components/Admin/Pages/WeBloom/Alert/Results/WeBloomResultAlertPage";
import WeBloomAnswerAlertPage from "../Components/Employee/Pages/WeBloom/Alert/Answer/WeBloomAnswerAlertPage";
import Counter from "../Components/Core/Counter";
import WeBloomCreateAlertPage from "../Components/Admin/Pages/WeBloom/Alert/Create/WeBloomCreateAlertPage";
import LinkCompaniesPage from "../Components/Backoffice/Pages/Company/LinkCompanies/LinkCompaniesPage";
import SuperAdminDashboardGroupPage from "../Components/SuperAdmin/Pages/Dashboard/Group/SuperAdminDashboardGroupPage";
import SuperAdminDashboardEntitiesPage
    from "../Components/SuperAdmin/Pages/Dashboard/Entities/SuperAdminDashboardEntitiesPage";
import WeBloomDashboardGroupPage from "../Components/SuperAdmin/Pages/WeBloom/Group/WeBloomDashboardGroupPage";
import WeBloomDashboardEntitiesPage from "../Components/SuperAdmin/Pages/WeBloom/Entities/WeBloomDashboardEntitiesPage";
import ColorsEditorPage from "../Components/Backoffice/Pages/Global/Colors/ColorsEditorPage";
import FiltersListPage from "../Components/Admin/Pages/Filter/FiltersListPage";
import FilterPage from "../Components/Admin/Pages/Filter/FilterPage";

const EbloomRouter = (props) => {

    return (
        <Routes>
            <Route path={"/login"} element={<PublicRoute component={LoginPage} restricted={true} {...props}/>}/>

            <Route path={"/resetPassword"} element={<PublicRoute component={ResetPassword} restricted={true}/>}/>

            <Route path={"/configTeams"} element={<PublicRoute component={TeamsConfig} restricted={false}/>}/>

            <Route path={"/createAccount"} element={<PublicRoute component={CreateAccountPage} restricted={false}/>}/>

            <Route path={"/counter"} element={<PublicRoute component={Counter} restricted={false}/>}/>

            <Route path={"/test"}
                   element={<PrivateRoute component={TestPage} roles={Roles.ADMIN_BACKOFFICE} modules={Modules.ALL} {...props}/>}/>

            <Route path="/employee/daily" element={<PrivateRoute component={DailySurveyPage} roles={Roles.ALL}
                                                                 modules={Modules.ALL} {...props}/>}/>

                <Route path={"/clicknbloom"} element={<PublicRoute component={PublicDailySurveyPage} restricted={true} {...props}/>}/>

                <Route path={"/profile"} element={<PrivateRoute component={ProfilePage} roles={Roles.ALL_BACKOFFICE_DEMO_DATATOPIA} modules={Modules.ALL} {...props}/>}/>

            <Route path={"/employee/dashboard"}
                   element={<PrivateRoute component={EmployeeDashboardPage} roles={Roles.ALL_BACKOFFICE}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route exact path="/webloom" element={<Navigate to={"/webloom/dashboard"}/>}/>

            <Route path={"/webloom/dashboard"}
                   element={<PrivateRoute component={WeBloomAnswerDashboardPage} roles={Roles.ALL}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/webloom/poll/answer/:id"}
                   element={<PrivateRoute component={WeBloomAnswerPollPage} roles={Roles.ALL}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/webloom/ideabox/answer/:id"}
                   element={<PrivateRoute component={WeBloomAnswerIdeaboxPage} roles={Roles.ALL}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/webloom/alert/answer/:id"} element={<PrivateRoute component={WeBloomAnswerAlertPage} roles={Roles.ALL} modules={Modules.ALL} {...props}/>}/>

            {/*USER ADVANCES ROUTE*/}

            <Route path={"/manager/dashboard"}
                   element={<PrivateRoute component={ManagerDashboardPage} roles={Roles.MORE_THAN_USER}
                                          modules={Modules.ALL} {...props}/>}/>

            {/*ADMIN ROUTE*/}

            <Route
                exact
                path="/admin"
                element={
                    <Navigate to={"/admin/users"}/>
                }
            />

            <Route path="/admin/users" element={<PrivateRoute component={EmployeeEditorPage} roles={Roles.ADMIN}
                                                              modules={Modules.NOT_PROSPECT} {...props}/>}/>

            <Route path="/admin/integration" element={<PrivateRoute component={IntegrationPage} roles={Roles.ADMIN}
                                                                    modules={Modules.NOT_PROSPECT} {...props}/>}/>

            <Route path="/admin/frequency" element={<PrivateRoute component={FrequencyPage} roles={[...Roles.SUPER_ADMIN,...Roles.ADMIN]}
                                                                  modules={Modules.NOT_FREE} {...props}/>}/>

            <Route path="/admin/questions/library/:idEntity"
                   element={<PrivateRoute component={LibraryPage} roles={[...Roles.SUPER_ADMIN,...Roles.ADMIN]}
                                          modules={Modules.NOT_FREE} {...props}/>}/>

            <Route path="/admin/questions/library" element={<PrivateRoute component={LibraryPage} roles={[...Roles.SUPER_ADMIN,...Roles.ADMIN]}
                                                                          modules={Modules.NOT_FREE} {...props}/>}/>

            <Route path="/admin/questions/playlist/:id/:idEntity?" //id entity for group playlist
                   element={<PrivateRoute component={PlaylistPage} roles={[...Roles.SUPER_ADMIN,...Roles.ADMIN]}
                                          modules={Modules.NOT_PROSPECT} {...props}/>}/>

            <Route path="/admin/questions/playlist"
                   element={<PrivateRoute component={PlaylistListPage} roles={[...Roles.SUPER_ADMIN,...Roles.ADMIN]}
                                          modules={Modules.NOT_PROSPECT} {...props}/>}/>

            <Route path="/admin/questions/planner" element={<PrivateRoute component={PlannerPage} roles={[...Roles.SUPER_ADMIN,...Roles.ADMIN]}
                                                                          modules={Modules.NOT_PROSPECT} {...props}/>}/>
            <Route path="/admin/questions/planner/:date?" element={<PrivateRoute component={PlannerPage} roles={[...Roles.SUPER_ADMIN,...Roles.ADMIN]}
                                                                          modules={Modules.NOT_PROSPECT} isDate={true} {...props}/>}/>

            <Route path="/admin/team/:id" element={<PrivateRoute component={TeamPage} roles={Roles.ADMIN}
                                                                 modules={Modules.MORE_THAN_HAPPY_NOT_PROSPECT} {...props}/>}/>

            <Route path="/admin/teams" element={<PrivateRoute component={TeamsPage} roles={Roles.ADMIN}
                                                              modules={Modules.MORE_THAN_HAPPY_NOT_PROSPECT} {...props}/>}/>

            <Route path="/admin/filters" element={<PrivateRoute component={FiltersListPage} roles={Roles.ADMIN}
                                                                modules={Modules.NOT_FREE} {...props}/>}/>
            <Route path="/admin/filters/:id" element={<PrivateRoute component={FilterPage} roles={Roles.ADMIN}
                                                                          modules={Modules.NOT_FREE} {...props}/>}/>

            <Route path={"/admin/questions/submit"}
                   element={<PrivateRoute component={SubmitCustomPage} roles={[...Roles.SUPER_ADMIN,...Roles.ADMIN]}
                                          modules={Modules.NOT_FREE} {...props}/>}/>

            <Route path={"/admin/communication"}
                   element={<PrivateRoute component={CommunicationPage} roles={Roles.ADMIN}
                                          modules={Modules.NOT_PROSPECT} {...props}/>}/>

            <Route path={"/admin/identity"} element={<PrivateRoute component={IdentityPage} roles={[...Roles.SUPER_ADMIN,...Roles.ADMIN]}
                                                                   modules={Modules.NOT_PROSPECT} {...props}/>}/>

            <Route path={"/admin/webloom"}
                   element={<PrivateRoute component={WeBloomDashboardPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/admin/webloom/poll/create"}
                   element={<PrivateRoute component={WeBloomCreatePollPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/group/webloom/poll/create/:idGroup"} //id entity for group
                   element={<PrivateRoute component={WeBloomCreatePollPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/admin/webloom/poll/result"}
                   element={<PrivateRoute component={WeBloomResultPollPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/group/webloom/poll/result/:idGroup"}
                   element={<PrivateRoute component={WeBloomResultPollPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/admin/webloom/ideabox/create"}
                   element={<PrivateRoute component={WeBloomCreateIdeaboxPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/group/webloom/ideabox/create/:idGroup"} //id entity for group
                   element={<PrivateRoute component={WeBloomCreateIdeaboxPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/admin/webloom/ideabox/result"}
                   element={<PrivateRoute component={WeBloomResultIdeaboxPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/group/webloom/ideabox/result/:idGroup"}
                   element={<PrivateRoute component={WeBloomResultIdeaboxPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/admin/webloom/message/create"} //id entity for group
                   element={<PrivateRoute component={WeBloomCreateMessagePage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/group/webloom/message/create/:idGroup"} //id entity for group
                   element={<PrivateRoute component={WeBloomCreateMessagePage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/admin/webloom/message/result"}
                   element={<PrivateRoute component={WeBloomResultMessagePage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/group/webloom/message/result/:idGroup"}
                   element={<PrivateRoute component={WeBloomResultMessagePage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/admin/webloom/alert/create"}
                       element={<PrivateRoute component={WeBloomCreateAlertPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                              modules={Modules.ALL} {...props}/>}/>

            <Route path={"/group/webloom/alert/create/:idGroup"} //id entity for group
                   element={<PrivateRoute component={WeBloomCreateAlertPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/admin/webloom/alert/result"}
                       element={<PrivateRoute component={WeBloomResultAlertPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                              modules={Modules.ALL} {...props}/>}/>

            <Route path={"/admin/webloom/alert/result/:id"}
                       element={<PrivateRoute component={WeBloomResultAlertPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                              modules={Modules.ALL} {...props}/>}/>

            <Route path={"/group/webloom/alert/result/:idGroup"}
                   element={<PrivateRoute component={WeBloomResultAlertPage} roles={[...Roles.SUPER_ADMIN,...Roles.MORE_THAN_USER_BACKOFFICE]}
                                          modules={Modules.ALL} {...props}/>}/>

            <Route path={"/admin/faq"}
                   element={<PrivateRoute component={FAQPage} roles={Roles.ADMIN} modules={Modules.NOT_PROSPECT} {...props}/>}/>

            <Route path="/admin/account" element={<PrivateRoute component={AccountPage} roles={[...Roles.SUPER_ADMIN,...Roles.ADMIN]}
                                                                modules={Modules.NOT_PROSPECT} {...props}/>}/>
            <Route path="/admin/customCategories" element={<PrivateRoute component={CustomCategoriesPage} roles={[...Roles.SUPER_ADMIN,...Roles.ADMIN]}
                                                                         modules={Modules.NOT_PROSPECT} {...props}/>}/>


            {/*SUPER ADMIN ROUTE*/}
            <Route
                exact
                path={"/group"}
                element={<Navigate to={"/group/dashboard"} {...props} />}
            />
                <Route path={"/group/dashboard"} element={
                    <PrivateRoute component={SuperAdminDashboardGroupPage} roles={Roles.SUPER_ADMIN} modules={Modules.NOT_PROSPECT} {...props}/>
                }/>
                <Route path={"/group/entities/dashboard"} element={
                    <PrivateRoute component={SuperAdminDashboardEntitiesPage} roles={Roles.SUPER_ADMIN} modules={Modules.NOT_PROSPECT} {...props}/>
                }/>
                <Route path={"/group/webloom/dashboard"} element={
                    <PrivateRoute component={WeBloomDashboardGroupPage} roles={Roles.SUPER_ADMIN} modules={Modules.NOT_PROSPECT} {...props} />
                }/>
                <Route path={"/group/entities/webloom/dashboard"} element={
                    <PrivateRoute component={WeBloomDashboardEntitiesPage} roles={Roles.SUPER_ADMIN} modules={Modules.NOT_PROSPECT} {...props} />
                }/>

            {/*BACKOFFICE ROUTE*/}

            <Route
                exact
                path="/backoffice"
                element={<Navigate to={"/backoffice/user"} {...props}/>}
            />

                <Route path={"/backoffice/user"} element={
                    <PrivateRoute component={UserEditorPage} roles={Roles.BACKOFFICE_GLOBAL_DATATOPIA} modules={Modules.SUPER_HAPPY} {...props}/>
                }/>

                <Route path={"/backoffice/company"} element={<PrivateRoute component={CompanyEditorPage} roles={Roles.BACKOFFICE_DEMO_DATATOPIA_GLOBAL} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/question"}
                   element={<PrivateRoute component={QuestionEditorPage} roles={Roles.BACKOFFICE}
                                          modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/category"}
                   element={<PrivateRoute component={CategoryEditorPage} roles={Roles.BACKOFFICE}
                                          modules={Modules.SUPER_HAPPY} {...props}/>}/>

                <Route path={"/backoffice/daily"} element={<PrivateRoute component={DailyEditorPage} roles={Roles.BACKOFFICE_DEMO_DATATOPIA_GLOBAL} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/dashboard"}
                   element={<PrivateRoute component={AdminDashboardPage} roles={Roles.BACKOFFICE}
                                          modules={Modules.SUPER_HAPPY} {...props}/>}/>

                <Route path={"/backoffice/employee"} element={<PrivateRoute component={BackofficeEmployeeEditorPage} roles={Roles.BACKOFFICE_GLOBAL_DATATOPIA} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/messages"} element={<PrivateRoute component={MessagePage} roles={Roles.BACKOFFICE}
                                                                        modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/tag"} element={<PrivateRoute component={TagEditorPage} roles={Roles.BACKOFFICE}
                                                                   modules={Modules.SUPER_HAPPY} {...props}/>}/>

                <Route path={"/backoffice/playlist"} element={<PrivateRoute component={PlaylistEditorPage} roles={Roles.BACKOFFICE_GLOBAL_DATATOPIA} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/notification"}
                   element={<PrivateRoute component={NotificationEditorPage} roles={Roles.BACKOFFICE}
                                          modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/logs"} element={<PrivateRoute component={LogPage} roles={Roles.BACKOFFICE}
                                                                    modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/setting"} element={<PrivateRoute component={SettingPage} roles={Roles.BACKOFFICE}
                                                                       modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/teams"} element={<PrivateRoute component={TeamViewPage} roles={Roles.BACKOFFICE}
                                                                     modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path="/backoffice/team/:id" element={<PrivateRoute component={TeamView} roles={Roles.BACKOFFICE}
                                                                      modules={Modules.SUPER_HAPPY} {...props}/>}/>

                <Route path={"/backoffice/playlists"} element={<PrivateRoute component={PlaylistViewPage} roles={Roles.BACKOFFICE_GLOBAL_DATATOPIA} modules={Modules.SUPER_HAPPY} {...props}/>}/>

                <Route path="/backoffice/questions/playlist/:id" element={<PrivateRoute component={PlaylistView} roles={Roles.BACKOFFICE_GLOBAL_DATATOPIA} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path="/backoffice/statistic"
                   element={<PrivateRoute component={StatisticPage} roles={Roles.BACKOFFICE}
                                          modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path="/backoffice/globalPlanner"
                   element={<PrivateRoute component={PlannerEditorPage} roles={Roles.BACKOFFICE}
                                          modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path="/backoffice/globalNotification"
                   element={<PrivateRoute component={NotificationPage} roles={Roles.BACKOFFICE}
                                          modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path="/backoffice/module" element={<PrivateRoute component={ModulePage} roles={Roles.BACKOFFICE}
                                                                    modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path="/backoffice/report"
                   element={<PrivateRoute component={BackofficeReport} roles={Roles.BACKOFFICE}
                                          modules={Modules.SUPER_HAPPY} {...props}/>}/>

                <Route path={"/backoffice/account"} element={<PrivateRoute component={BackofficeAccountPage} roles={Roles.BACKOFFICE_GLOBAL_DATATOPIA} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/globalDashboard"}
                   element={<PrivateRoute component={GlobalDashboardPage} roles={Roles.BACKOFFICE}
                                          modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/webloom"}
                   element={<PrivateRoute component={BackofficeWeBloomPage} roles={Roles.BACKOFFICE}
                                          modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/faq"} element={<PrivateRoute component={FAQEditorPage} roles={Roles.BACKOFFICE}
                                                                   modules={Modules.ALL} {...props}/>}/>

            <Route path={"/backoffice/filters"}
                   element={<PrivateRoute component={FilterEditorPage} roles={Roles.BACKOFFICE}
                                          modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/templates"}
                   element={<PrivateRoute component={WeBloomTemplatePage} roles={Roles.BACKOFFICE}
                                          modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/identity"} element={<PrivateRoute component={BackofficeIdentityPage} roles={Roles.BACKOFFICE_GLOBAL_DATATOPIA} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/frequency"} element={<PrivateRoute component={BackofficeFrequencyPage} roles={Roles.BACKOFFICE_GLOBAL_DATATOPIA} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/library"} element={<PrivateRoute component={BackofficeLibraryPage} roles={Roles.BACKOFFICE_GLOBAL_DATATOPIA} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/customCategories"} element={<PrivateRoute component={BackofficeCustomCategoriesPage} roles={Roles.BACKOFFICE_GLOBAL_DATATOPIA} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/mobile"} element={<PrivateRoute component={MobileEditorPage} roles={Roles.BACKOFFICE} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/integration"} element={<PrivateRoute component={IntegrationEditorPage} roles={Roles.BACKOFFICE} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/linkCompanies"} element={<PrivateRoute component={LinkCompaniesPage} roles={Roles.BACKOFFICE} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path={"/backoffice/colorsEditor"} element={<PrivateRoute component={ColorsEditorPage} roles={Roles.BACKOFFICE} modules={Modules.SUPER_HAPPY} {...props}/>}/>

            <Route path="/" element={
                    Login.isLogin() ?
                        (Login.hasRole("demo_backoffice") || Login.hasRole("datatopia_backoffice")) ?
                            <Navigate to={"/backoffice/company"} {...props}/>
                            :
                            Login.hasRole("global_backoffice") ?
                                <Navigate to={"/backoffice/user"} {...props}/>
                               : Login.hasRole("global_super_admin") ?
                                <Navigate to={"/group/dashboard"} {...props}/>
                                   : Login.hasRole("global_admin") && Login.hasOneOfModules(Modules.PROSPECT) ?
                                        <Navigate to={"/manager/dashboard"} {...props}/>
                                        : Login.hasRole("global_admin") ?
                                            <Navigate to={"/admin/users"} {...props}/>
                                            :
                                            <Navigate to={"/employee/daily"} {...props}/>
                            :
                            <Navigate to={"/login"} {...props}/>
                }/>

            {/*Route doesn't match -> redirect to home*/}

            <Route path="*" element={<Navigate to={"/"} {...props}/>}/>
        </Routes>
    )

}

export default EbloomRouter;