import React from 'react';
import {Row, Col} from "react-bootstrap";
import {useTranslation} from "react-multi-lang/lib";
import _ from "lodash";
import Typography from "@mui/material/Typography/Typography";
import smileyUnhappyIcon from "../../../Core/symbols/smileys/smileyUnhappy.svg";
import smileyUnhappyGreyIcon from "../../../Core/symbols/smileys/smileyUnhappyGrey.svg";
import smileySadIcon from "../../../Core/symbols/smileys/smileySad.svg";
import smileySadGreyIcon from "../../../Core/symbols/smileys/smileySadGrey.svg";
import smileyNeutralIcon from "../../../Core/symbols/smileys/smileyNeutral.svg";
import smileyNeutralGreyIcon from "../../../Core/symbols/smileys/smileyNeutralGrey.svg";
import smileyGladIcon from "../../../Core/symbols/smileys/smileyGlad.svg";
import smileyGladGreyIcon from "../../../Core/symbols/smileys/smileyGladGrey.svg";
import smileyHappyGreyIcon from "../../../Core/symbols/smileys/smileyHappyGrey.svg";
import smileyHappyIcon from "../../../Core/symbols/smileys/smileyHappy.svg";


const AnswerSmileys = (props) => {

        const {bp, question, handleSmileyClick} = props;

        const t = useTranslation();

        let smileySize = bp.isMobileOrLess() ? "h-80 w-80" : "h-60 w-60";
        let scalePosition = (bp.isTablet()) ? "large_scale" : "basic_scale";
        const isMobile = bp.isMobileLandscapeOrLess();
        const isTablet = bp.isTablet();
        const isLandscapeTablet = bp.isTabletLandscape();

      const handleClick = (score) => event => {
          event.preventDefault();
          handleSmileyClick(score, question);
      };

      const variant =  bp.pageSize > bp.breakpoints.mobileLandscape ? "h4" : "h5";

      const notApplicableToMe = question && question.score && question.score === -1;

      const scores = [1, 2, 3, 4, 5];

      const displaySmiley = (score) => {
          switch (score){
              case 1:
                  return notApplicableToMe ? smileyUnhappyGreyIcon : smileyUnhappyIcon;
              case 2:
                  return notApplicableToMe ? smileySadGreyIcon : smileySadIcon;
              case 3:
                  return notApplicableToMe ? smileyNeutralGreyIcon : smileyNeutralIcon;
              case 4:
                  return notApplicableToMe ? smileyGladGreyIcon : smileyGladIcon;
              case 5:
                  return notApplicableToMe ? smileyHappyGreyIcon : smileyHappyIcon;
          }
      }


    return(
      <>
          <div>
              <Typography variant={variant} className={"text-center text-contrast-purple mt10 " + (isMobile ? "mb-0 h-160" : isTablet ? "mb-50 h-125" : "mb-0 h-125")}>
                  {question.text}
              </Typography>

          </div>

              <div className="flex justify-center">
                  {
                      scores.map((score) => (
                          <img src={displaySmiley(score)} className={(question.score === (score) ? 'active' : notApplicableToMe ? "smileyStayInactive" : "smileyInactive") + " pointer ml-2 mr-2 " + smileySize} onClick={handleClick((score))}/>
                      ))
                  }
              </div>
          <Row className={scalePosition + " mb-15"}>
              <Col className={(isMobile ? "pl-8 pr-5" : isTablet ? "pl-0 pr-0" : isLandscapeTablet ? "pl-90 pr-90" : "pl-45 pr-45")}>
                  <span className={"ebloom-small-text ebloom-text float-left text-" + (notApplicableToMe ? "grey" : "contrast-purple")}>{_.capitalize(t("core.notAtAll"))}</span>
                  <span className={"ebloom-small-text ebloom-text float-right text-" + (notApplicableToMe ? "grey" : "contrast-purple")}>{_.capitalize(t("core.absolutely"))}</span>
              </Col>
          </Row>
      </>
    )


}

export default AnswerSmileys;