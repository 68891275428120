import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import HttpApi, {Sources} from "../../../../httpApi";
import {notifyError} from "../../../../Utils/Notification";
import {useTranslation} from "react-multi-lang/lib";
import CategoryTag from "../CategoryDisplay/CategoryTag";
import EbloomSmileyProgressBar from "./EbloomSmileyProgressBar";
import {CompanyTypes, FullContrastPurpleTextField, Modules, Roles} from "../../../../Utils/Global";
import {MenuItem} from "@mui/material";
import _ from "lodash";
import {ReactComponent as AlertIcon} from "../../../Core/symbols/webloom/white/danger-white.svg";
import EbloomTooltip from "../Popovers-Tooltips/EbloomTooltip";
import LoadingView from "../../Layouts/LoadingView";
import {useUserData} from "../../../../hooks/userHook";
import {LockClockOutlined} from "@mui/icons-material";
import EbloomInfoTooltip from "../Popovers-Tooltips/EbloomInfoTooltip";
import EbloomSpeedometerV2 from "./EbloomSpeedometerV2";
import {alpha, createTheme} from "@mui/material/styles";
import {Colors} from "../../../../Utils/Colors";

const EbloomHeatmap = (props) => {
    const {filters, teams, startDate, endDate, companyToEdit, teamToDisplay, company, benchmark,isModelCustomEnable,isSuperAdmin,isAllEntities,isOneEntitySelected,isTeamSelected,isGlobalEntitySelected,colors,commonSurveyActivated,checkAdvancedUserTeams} = props;
    const [loading, setLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(teams.length > 1 ? 0 : filters.length > 0 ? filters[0].id : -1);
    const [categories, setCategories] = useState([]);
    const [heatMapData, setHeatMapData] = useState([]);
    const [companyData, setCompanyData] = useState(null);

    const t = useTranslation();
    const user = useUserData();
    const isProspect = user.companyType === CompanyTypes.PROSPECT
    const isAdvanced = user.roles.includes(Roles.ADVANCED_USER[0])
    const companyName = companyToEdit ? companyToEdit.name : isSuperAdmin && !isOneEntitySelected ? user.companyGroup.name : isOneEntitySelected ? teamToDisplay[0].name :!isProspect ? user.company : _.upperFirst(t("core.company.company"));
    const idCompanyCheck = companyToEdit ? companyToEdit.id : isSuperAdmin ? user.companyGroup.id : 0
    const isCompanyEditGroup = companyToEdit && companyToEdit.type === CompanyTypes.GROUP

    const prospectData = [
        {
            id: -100,
            name: _.upperFirst(t("core.generation")),
            filters : [
                {
                    id_team : -99,
                    score : 68,
                    scores : [
                        {
                            category :{
                                id: 1,
                                name: "Work & Lifestyle",
                                color: "#C4E4FF",
                                icon: "01",
                                position: 1
                            },
                            score : 77,
                            total : 20
                        },
                        {
                            category :{
                                id: 2,
                                name: "Team & Interaction",
                                color: "#D4FBF2",
                                icon: "02",
                                position: 2
                            },
                            score : 63,
                            total : 20
                        },
                        {
                            category :{
                                id: 3,
                                name: "Management & Leadership",
                                color: "#F5E1FD",
                                icon: "03",
                                position: 3
                            },
                            score : 58,
                            total : 20
                        },
                        {
                            category :{
                                id: 4,
                                name: "Policy & Support",
                                color: "#E1E1ED",
                                icon: "04",
                                position: 4
                            },
                            score : -1,
                            total : 20
                        },
                        {
                            category :{
                                id: 5,
                                name: "Adhesion & Culture",
                                color: "#FDF2D1",
                                icon: "05",
                                position: 5
                            },
                            score : 85,
                            total : 20
                        }
                    ],
                    team : "Gen - X",
                    users: ["1","2","3","4","5"]
                },
                {
                    id_team : -98,
                    score : 59,
                    scores : [
                        {
                            category :{
                                id: 1,
                                name: "Work & Lifestyle",
                                color: "#C4E4FF",
                                icon: "01",
                                position: 1
                            },
                            score : 58,
                            total : 20
                        },
                        {
                            category :{
                                id: 2,
                                name: "Team & Interaction",
                                color: "#D4FBF2",
                                icon: "02",
                                position: 2
                            },
                            score : 82,
                            total : 20
                        },
                        {
                            category :{
                                id: 3,
                                name: "Management & Leadership",
                                color: "#F5E1FD",
                                icon: "03",
                                position: 3
                            },
                            score : 35,
                            total : 20
                        },
                        {
                            category :{
                                id: 4,
                                name: "Policy & Support",
                                color: "#E1E1ED",
                                icon: "04",
                                position: 4
                            },
                            score : 74,
                            total : 20
                        },
                        {
                            category :{
                                id: 5,
                                name: "Adhesion & Culture",
                                color: "#FDF2D1",
                                icon: "05",
                                position: 5
                            },
                            score : 45,
                            total : 20
                        }
                    ],
                    team : "Gen - Y",
                    users: ["1","2","3","4","5"]
                },
                {
                    id_team : -97,
                    score : 83,
                    scores : [
                        {
                            category :{
                                id: 1,
                                name: "Work & Lifestyle",
                                color: "#C4E4FF",
                                icon: "01",
                                position: 1
                            },
                            score : 63,
                            total : 20
                        },
                        {
                            category :{
                                id: 2,
                                name: "Team & Interaction",
                                color: "#D4FBF2",
                                icon: "02",
                                position: 2
                            },
                            score : 81,
                            total : 20
                        },
                        {
                            category :{
                                id: 3,
                                name: "Management & Leadership",
                                color: "#F5E1FD",
                                icon: "03",
                                position: 3
                            },
                            score : 72,
                            total : 20
                        },
                        {
                            category :{
                                id: 4,
                                name: "Policy & Support",
                                color: "#E1E1ED",
                                icon: "04",
                                position: 4
                            },
                            score : 97,
                            total : 20
                        },
                        {
                            category :{
                                id: 5,
                                name: "Adhesion & Culture",
                                color: "#FDF2D1",
                                icon: "05",
                                position: 5
                            },
                            score : 51,
                            total : 20
                        }
                    ],
                    team : "Gen - Z",
                    users: ["1","2","3","4","5"]
                },
            ],
        },
        {
            id: -99,
            name: _.upperFirst(t("core.contract")),
            filters : [
                {
                    id_team : -96,
                    score : 86,
                    scores : [
                        {
                            category :{
                                id: 1,
                                name: "Work & Lifestyle",
                                color: "#C4E4FF",
                                icon: "01",
                                position: 1
                            },
                            score : 87,
                            total : 20
                        },
                        {
                            category :{
                                id: 2,
                                name: "Team & Interaction",
                                color: "#D4FBF2",
                                icon: "02",
                                position: 2
                            },
                            score : 78,
                            total : 20
                        },
                        {
                            category :{
                                id: 3,
                                name: "Management & Leadership",
                                color: "#F5E1FD",
                                icon: "03",
                                position: 3
                            },
                            score : 90,
                            total : 20
                        },
                        {
                            category :{
                                id: 4,
                                name: "Policy & Support",
                                color: "#E1E1ED",
                                icon: "04",
                                position: 4
                            },
                            score : 84,
                            total : 20
                        },
                        {
                            category :{
                                id: 5,
                                name: "Adhesion & Culture",
                                color: "#FDF2D1",
                                icon: "05",
                                position: 5
                            },
                            score : 92,
                            total : 20
                        }
                    ],
                    team : _.upperFirst(t("employee.employee")),
                    users: ["1","2","3","4","5"]
                },
                {
                    id_team : -95,
                    score : 60,
                    scores : [
                        {
                            category :{
                                id: 1,
                                name: "Work & Lifestyle",
                                color: "#C4E4FF",
                                icon: "01",
                                position: 1
                            },
                            score : 63,
                            total : 20
                        },
                        {
                            category :{
                                id: 2,
                                name: "Team & Interaction",
                                color: "#D4FBF2",
                                icon: "02",
                                position: 2
                            },
                            score : 77,
                            total : 20
                        },
                        {
                            category :{
                                id: 3,
                                name: "Management & Leadership",
                                color: "#F5E1FD",
                                icon: "03",
                                position: 3
                            },
                            score : 54,
                            total : 20
                        },
                        {
                            category :{
                                id: 4,
                                name: "Policy & Support",
                                color: "#E1E1ED",
                                icon: "04",
                                position: 4
                            },
                            score : 42,
                            total : 20
                        },
                        {
                            category :{
                                id: 5,
                                name: "Adhesion & Culture",
                                color: "#FDF2D1",
                                icon: "05",
                                position: 5
                            },
                            score : 65,
                            total : 20
                        }
                    ],
                    team : _.upperFirst(t("prospect.freelance")),
                    users: ["1","2","3","4","5"]
                },
            ]
        }
    ]


    const fetchMainCategories = () => {
        let url = "/categories/getMainCategories"
        if(isSuperAdmin){
           url = "/categories/getMainCategories/"+ idCompanyCheck
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setCategories(response.data);
            }else{
                setCategories([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchHeatMapData = () => {
        if(selectedFilter <= -99 && isProspect){
            const prospectHeatMapData = prospectData[selectedFilter + 100].filters
            setHeatMapData(prospectHeatMapData)
            setLoading(false)
        }else if(!!companyToEdit || (!!teamToDisplay && teamToDisplay.length > 0)){
            const params = !!teamToDisplay && teamToDisplay.length > 0 ? teamToDisplay.map(el => String(el.id)).join('-') : '0'
            let url = "/feedbacks/getScoreTable/" + startDate + "/" + endDate + "/" + selectedFilter+"/"+params+"/"+commonSurveyActivated;
            let HttpApiCall = HttpApi.getV2
            if(isSuperAdmin || isCompanyEditGroup){
                HttpApiCall = HttpApi.get
                url = isCompanyEditGroup ? "/dashboard/backoffice/group/find/teamsAndCategories/"+ companyToEdit.id+ "/" + startDate + "/" + endDate + "/" + selectedFilter : "dashboard/group/find/teamsAndCategories/" + startDate + "/" + endDate + "/" + selectedFilter
            }
            if(isAllEntities){
                HttpApiCall = HttpApi.get
                url = "/dashboard/entities/find/teamsAndCategories/" + startDate + "/" + endDate + "/" + selectedFilter
                if(isOneEntitySelected){
                    const idEntity = teamToDisplay[0].id
                    url = "/dashboard/entities/teams/find/teamsAndCategories/"+ idEntity + "/" + startDate + "/" + endDate + "/" + selectedFilter
                }
            }
            setLoading(true);
            if(Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module) && selectedFilter === -3){//benchmark vs company
                let data = [
                    {team:companyName, score:Math.round(company.bloomIndex), scores:company.categories, users:["1","2","3", "4", "5"]},
                    {team:"Benchmark", score:Math.round(benchmark.bloomIndex), scores:benchmark.categories, users:["1","2","3", "4", "5"]}
                ]
                setHeatMapData(data);
                setTimeout(() => {
                    setLoading(false);
                }, 100);

            }else{
                HttpApiCall(url, Sources.HEAT_MAP_DATA).then(response => {
                    if(response && response.data){
                        let heatmapResult = response.data
                        if(isProspect){
                            if(selectedFilter === 0){
                                heatmapResult = heatmapResult.map((el,index) => {
                                    if(el.id !== 0 ){
                                        el.team = _.upperFirst(t("admin.team")) +" "+String.fromCharCode(index + 'A'.charCodeAt(0))
                                    }
                                    return el
                                })
                            }else{
                                heatmapResult = heatmapResult.slice(0,response.data.length >= 3 ? 3 : response.data.length).map((el,index) => {
                                    const mapFilter = [_.upperFirst(t("employee.employee")),_.upperFirst(t("prospect.freelance")),"Gen - X","Gen - Y","Gen - Z"]
                                    if(el.id !== 0 ){
                                        el.team = response.data.length >= 3 ? mapFilter[index+2] : mapFilter[index];
                                        return el
                                    }
                                    return el
                                })
                            }

                        }

                        setHeatMapData(heatmapResult);
                        if(checkAdvancedUserTeams([0])){
                            const companyData = {team:commonSurveyActivated? _.upperFirst(t("core.avgScore")) :companyName, score:Math.round(company.bloomIndex), scores:company.categories, users:["1","2","3", "4", "5"]}
                            setCompanyData(companyData)
                        }

                    }else{
                        setHeatMapData([]);
                    }
                }).catch(error => {
                    notifyError(error);
                }).finally(() => {
                    setLoading(false);
                })
            }
        }
    }

    const handleChange = (event) => {
        setSelectedFilter(event.target.value);
    }

    const validateFilter = (filter) => {
        return !filter?.filters.find(el => el.isValid === true);
    }


    const checkMenuSelect = () => {
        const baseCondition = (
            (teams.length > 0 && isAdvanced) ||
            (teams.length > 1 && filters.length > 0) ||
            filters.length > 1 ||
            (teams.length > 2 && Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module)) ||
            (filters.length > 0 && Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module))
        )
        if(isSuperAdmin){
            if (isAllEntities){
                return baseCondition && ((isAllEntities && isOneEntitySelected && !isTeamSelected) || (teamToDisplay && teamToDisplay.length === 1 && teamToDisplay[0].id === idCompanyCheck))
            }else{
                return (isGlobalEntitySelected)
            }
        }else{
            return baseCondition && (teamToDisplay && teamToDisplay.length === 1 && teamToDisplay[0].id === 0 && !isAdvanced && !commonSurveyActivated)
        }

    }
    const themeColors = createTheme({
        primary : colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE,
    })

    useEffect(() => {
        fetchMainCategories()
    }, []);

    useEffect(() =>{
        if(isAdvanced || commonSurveyActivated){
            setSelectedFilter(0);
        }else if(teamToDisplay && teamToDisplay.length >= 1 && teamToDisplay[0].id > 0 && teamToDisplay[0].id !== idCompanyCheck){
            setSelectedFilter(0);
        }else if(Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module) && teams.filter(el => el.id >= 0).length < 2 && filters.length < 1){
            setSelectedFilter(-3);
        }else if(teams.length >= 2){
            setSelectedFilter(0);
        }else if(Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module) && filters.length > 0){
            setSelectedFilter(filters[0].id);
        }
    }, [companyToEdit, teamToDisplay, benchmark, company, teams, filters,isOneEntitySelected,isGlobalEntitySelected]);

    useEffect(() => {
        fetchHeatMapData();
    }, [startDate, endDate, selectedFilter,isOneEntitySelected,isGlobalEntitySelected,company,commonSurveyActivated,teamToDisplay]);

    useEffect(() => {
        if(selectedFilter === -3){
            let data = [
                {team:companyName, score:Math.round(company.bloomIndex), scores:company.categories, users:["1","2","3", "4", "5"]},
                {team:"Benchmark", score:Math.round(benchmark.bloomIndex), scores:benchmark.categories, users:["1","2","3", "4", "5"]}
            ]
            setHeatMapData(data);
        }
    }, [benchmark,company]);


    return(
        <div className={"w-full max-h-500 relative overflow-y-auto overflow-x-auto"}>
            <table className={"w-full h-full border-collapse"}>
                <thead className={"sticky top-0 z-50 bg-white"}>
                <tr className={"relative"}>
                    <th scope={"col"} className={"sticky top-0 left-0 z-20 bg-white pr-20"}>
                        {
                            checkMenuSelect() ?
                                <FullContrastPurpleTextField theme={themeColors} variant={"outlined"} size={"small"}
                                                             value={selectedFilter} onChange={handleChange} select
                                                             SelectProps={{
                                                                 MenuProps: {
                                                                     sx: {
                                                                         "&& .Mui-selected": {
                                                                             backgroundColor: alpha(colors ? colors.secondary : Colors.EBLOOM_PURPLE, 0.35)
                                                                         },
                                                                     }
                                                                 }
                                                             }}
                                >
                                    <MenuItem key={-2} value={-1} disabled>{_.upperFirst(t("core.select"))}</MenuItem>
                                    {
                                        teams && ((Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module) && teams.length > 2) || (!Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module) && teams.length > 1)) &&
                                        <MenuItem key={-1}
                                                  value={0}>{isSuperAdmin && !isOneEntitySelected ? _.upperFirst(t("core.entities")) : _.upperFirst(t("admin.teams"))}</MenuItem>
                                    }
                                    {
                                        user.module === "free" &&
                                        <MenuItem key={-3} value={-2} disabled>
                                            <div>
                                                <span>{_.upperFirst(t("core.filter")) + " 1"}</span><LockClockOutlined/>
                                            </div>
                                        </MenuItem>
                                    }
                                    {
                                        isProspect && (teamToDisplay.length === 1 && teamToDisplay[0].id === idCompanyCheck) &&
                                        prospectData.map((filter, index) => {
                                            return <MenuItem key={index} value={filter.id}>{filter.name}</MenuItem>
                                        })
                                    }
                                    {
                                        !isProspect && (isOneEntitySelected || (teamToDisplay && teamToDisplay.length === 1 && teamToDisplay[0].id === idCompanyCheck)) && filters && filters.length > 0 &&
                                        filters.map((filter, index) => (
                                            <MenuItem key={index} value={filter?.id}
                                                      disabled={validateFilter(filter)}>{filter.name}</MenuItem>
                                        ))
                                    }
                                    {
                                        Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module) &&
                                        <MenuItem key={-4} value={-3} className={"w-full"}>
                                            <div className={"z-10 w-full"}>
                                                <EbloomTooltip text={benchmark.tooltip} placement={"right"}>
                                                    <div className={"w-full"}>Benchmark</div>
                                                </EbloomTooltip>
                                            </div>
                                        </MenuItem>


                                    }
                                </FullContrastPurpleTextField>
                                :
                                <div style={{color: colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}}
                                     className={"ebloom-large-text ebloom-text text-center"}>{isSuperAdmin ? isAllEntities && isOneEntitySelected ? _.upperFirst(t("admin.teams")) : _.upperFirst(t("core.entities")) : ((Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module) && teams.length > 2) || (!Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module) && teams.length > 1)) || (isAdvanced) ? _.upperFirst(t("admin.teams")) : filters && filters[0] ? filters[0].name : Modules.MORE_THAN_HAPPY_NOT_FREE.includes(user.module) ?
                                    <EbloomTooltip text={benchmark.tooltip} placement={"right"}>
                                        <div className={"w-full"}>Benchmark</div>
                                    </EbloomTooltip> : ""}</div>
                        }
                    </th>
                    {
                        categories && categories.sort((a, b) => a.position - b.position).map((category, index) => (
                            <th key={index} scope={"col"} className={" sticky top-0 bg-white z-10 pr-20"}>
                                <CategoryTag modelCustomEnable={isModelCustomEnable} category={category}
                                             width={categories.length > 5 ? 100 : 130}/>
                            </th>
                        ))
                    }
                    <th className={"sticky top-0 right-0 z-20 bg-white"}>
                        <div style={{color: colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}}
                             className={"ebloom-normal-text font-bold mr-15 justify-self-center "}>
                            Bloom Index
                        </div>
                    </th>
                </tr>
                {
                    !!company && selectedFilter !== -3 && (!isSuperAdmin || (isSuperAdmin && ((isAllEntities && isOneEntitySelected) || !isAllEntities))) && !!companyData && checkAdvancedUserTeams([0])  &&
                    <tr className={""}>
                        <th scope={"col"} style={{color: colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}}
                            className={"ebloom-normal-text font-bold  py-20 pr-20 sticky top-30 left-0 z-50 bg-white"}>
                            <div style={{color: colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}}>
                                <span>{companyData.team}</span>
                            </div>
                        </th>
                        {
                            companyData.scores && companyData.scores.sort((a, b) => a.category.position - b.category.position).map((category, index) => (
                                <td key={index} className={"bg-white"}>
                                    <div className={""}>
                                        <EbloomSmileyProgressBar staticSmiley={false} avg={category.score} isHeatMap={true}
                                                                 barWidth={60} showMinimumView={categories.length > 5}/>
                                    </div>
                                </td>
                            ))
                        }
                        <td className={"flex items-center py-20 sticky right-0 top-30  z-10 bg-white " + (categories.length > 5 && 'pr-15')}>
                            {
                                loading ?
                                    <LoadingView/>
                                    :
                                    <EbloomSpeedometerV2 score={companyData.score} width={100} showScore={false}
                                                         showLegend={false} colors={colors}/>
                            }
                            <div style={{color: colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}}
                                 className={"ebloom-normal-text"}>{companyData.score === -1 ? _.upperFirst(t("manager.dashboard.anonymityRestrictionSmall")) : companyData.score}</div>
                        </td>
                    </tr>
                }
                </thead>
                <tbody className={"relative"}>
                {
                    heatMapData && heatMapData.filter(el => el.users.length > 0 || isSuperAdmin).map((heatMap, index) => (
                        <tr key={index}>
                            <th scope={"row"} style={{color: colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}}
                                className={"ebloom-normal-text font-bold  py-20 pr-20 sticky left-0 z-10 bg-white"}>
                                {
                                    (selectedFilter === -3 && heatMap.team === "Benchmark") ?
                                        <div className={"flex flex-row justify-center items-center"}>
                                            <div style={{color: colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}}>{
                                                heatMap.users.length < 5 ?
                                                    <div className={"flex justify-center items-center"}>
                                                        {heatMap.team}
                                                        <EbloomTooltip
                                                            text={_.upperFirst(t("manager.dashboard.anonymityRestrictionPeople"))}>
                                                            <AlertIcon className={"red-icon w-25 h-25 ml-5"}/>
                                                        </EbloomTooltip>
                                                    </div>
                                                    :
                                                    <span>{heatMap.team}</span>
                                            }
                                            </div>
                                            <EbloomInfoTooltip size={"sm"} text={benchmark.tooltip}/>
                                        </div>

                                        :
                                        <div style={{color: colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE}}>
                                            {
                                                heatMap.users.length < 5 ?
                                                    <div className={"flex justify-center items-center"}>
                                                        {heatMap.team}
                                                        <EbloomTooltip
                                                            text={_.upperFirst(t("manager.dashboard.anonymityRestrictionPeople"))}>
                                                            <AlertIcon className={"red-icon w-25 h-25 ml-5"}/>
                                                        </EbloomTooltip>
                                                    </div>
                                                    : <span>{heatMap.team}</span>
                                            }</div>
                                }
                            </th>
                            {
                                heatMap.scores && heatMap.scores.sort((a, b) => a.category.position - b.category.position).map((category, index) => (
                                    <td key={index} className={" py-20"}>
                                        <div className={""}>
                                            <EbloomSmileyProgressBar staticSmiley={false} avg={category.score} isHeatMap={true}
                                                                     barWidth={60} showMinimumView={categories.length > 5}/>
                                        </div>
                                    </td>
                                    ))
                                }
                                <td className={"flex items-center py-20 sticky right-0  bg-white " + (categories.length > 5 && 'pr-15')}>
                                    {
                                        loading ?
                                            <LoadingView/>
                                            :
                                            <EbloomSpeedometerV2 score={heatMap.score} width={100} showScore={false} showLegend={false} colors={colors}/>
                                    }
                                    <div style={{color : colors ? colors.primary : Colors.EBLOOM_CONTRAST_PURPLE }} className={"ebloom-normal-text"}>{heatMap.score === -1 ? _.upperFirst(t("manager.dashboard.anonymityRestrictionSmall")) : heatMap.score}</div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

EbloomHeatmap.propTypes = {
    filters:PropTypes.array.isRequired,
    startDate:PropTypes.any.isRequired,
    endDate:PropTypes.any.isRequired,
    companyToEdit:PropTypes.object,
    company:PropTypes.object,
    benchmark:PropTypes.object,
    colors:PropTypes.object,
    isModelCustomEnable:PropTypes.bool,
    isSuperAdmin:PropTypes.bool,
    isAllEntities:PropTypes.bool,
    isOneEntitySelected:PropTypes.bool,
    isTeamSelected:PropTypes.bool,
    isGlobalEntitySelected:PropTypes.bool,
    commonSurveyActivated:PropTypes.bool,
    checkAdvancedUserTeams:PropTypes.func
}

EbloomHeatmap.defaultProps = {
    isSuperAdmin : false,
    commonSurveyActivated : false,
    isAllEntities : false,
    isOneEntitySelected : false,
    isTeamSelected : false,
    isGlobalEntitySelected : false,
    colors : null,
    checkAdvancedUserTeams : () => {return false}
}

export default EbloomHeatmap;