import {ModelTypes} from "./Types";
import HttpApi from "../httpApi";
import _ from "lodash";
import Login from "../login/login";
import {getFromStorage} from "../Utils/Storage";
import {getLanguage} from "react-multi-lang/lib";
import {getTranslations} from "../lang/translations";
import { validPassword } from '../Utils/Global';

const isBackoffice = localStorage.getItem("ebloom-companyToEdit") !== null;
const id_company = isBackoffice ? Number(JSON.parse(localStorage.getItem("ebloom-companyToEdit")).id) : null;

const User = {
    crud:{
        create:"/users/create",
        update:"/users/update",
        delete:"/users/delete"
    },
    productTour: {
        view : 'buttonUsersView',
        edit : 'buttonUsersEdit',
    },

    initializeOptions: () => {
        if(Login.hasModule("free") || Login.hasModule("very_happy") || Login.hasModule("super_happy")){
            HttpApi.getV2("/teams/getAll").then(response => {
                if (response && response.data) {
                    User.id_team.form.selectOptions.options = response.data;
                    let teams = _.cloneDeep(response.data);
                    teams.splice(0, 0, {
                        id: 0,
                        name: isBackoffice ? JSON.parse(localStorage.getItem("ebloom-companyToEdit")).company : getFromStorage("company")
                    })
                    User.teams_collab.form.selectOptions.options = teams;
                    User.teams.form.selectOptions.options = teams;
                    User.id_team.form.show = true;
                    User.teams_collab.form.show = true;
                    User.teams.form.show = true;
                } else {
                    let teams = [{
                        id: 0,
                        name: isBackoffice ? JSON.parse(localStorage.getItem("ebloom-companyToEdit")).company :  getFromStorage("company")
                    }]
                    User.id_team.form.show = false;
                    User.id_team.form.selectOptions.options = [];
                    User.teams_collab.form.selectOptions.options = teams;
                    User.teams.form.selectOptions.options = teams;
                    User.teams_collab.form.show = true;
                    User.teams.form.show = true;
                }
            });
        }else{ //module starter, no teams
            let teams = [{
                id: 0,
                name: isBackoffice ? JSON.parse(localStorage.getItem("ebloom-companyToEdit")).company :  getFromStorage("company")
            }]
            User.id_team.form.show = false;
            User.teams_collab.form.selectOptions.options = teams;
            User.teams_collab.form.selectOptions.showAll = false;
            User.teams.form.selectOptions.options = teams;
            User.teams.form.selectOptions.showAll = false;
            User.teams_collab.form.show = true;
            User.teams.form.show = true;
            User.team.detail.show = false
        }

        HttpApi.getV2("/roles/getBasic").then(response => {
            if (response && response.data) {
                User.id_roles.form.show = true;
                User.id_roles.form.selectOptions.options = response.data;
            } else {
                User.id_roles.form.selectOptions.options = [];
                User.id_roles.form.show = false;
            }
        });

        if(Login.hasModule("very_happy") || Login.hasModule("super_happy")){
            HttpApi.getV2("/filters/getAllAdmin").then(response => {
                if(response && response.data){
                    User.filters.form.show = true;
                    User.filters.form.selectOptions.options = response.data.sort((a,b) => {if(a.company_filter<b.company_filter){return -1}else if(a.company_filter>b.company_filter){return 1} return 0});
                }else{
                    User.filters.form.selectOptions.options = [];
                    User.filters.form.show = false;
                }
            })
        }else{
            User.filters.form.selectOptions.options = [];
            User.filters.form.show = false;
            User.filters.detail.show = false;
        }
    },

    id:{
        id:"id",
        type:ModelTypes.NUMBER,
        label:"id",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:false
        },
        detail:{
            show:false
        }
    },

    displayName:{
        id:"displayName",
        type:"TEXT",
        label:"core.displayName",
        table:{
            show:true
        },
        list:{
            show:true,
        },
        form:{
            show:false,
        },
        detail:{
            show:false
        }
    },

    email:{
        id:"email",
        type:"EMAIL",
        label:"core.login.email",
        table:{
            show:false
        },
        list:{
            show:false,
        },
        form:{
            show:true,
            id:"email-profile",
            label:"core.login.email",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            readOnly:false,
            name:"email",
            error: (email) => {
                const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return Boolean(email && !regex.test(String(email).toLowerCase()))}
        },
        detail:{
            show:true,
            label:"Identifiant"
        }
    },

    phone:{
        id:"phone",
        type:"PHONE",
        label:"core.login.phone",
        table:{
            show:false
        },
        list:{
            show:false,
        },
        form:{
            show:true,
            id:"phone-profile",
            label:"core.login.phone",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"email",
            placeholder:"+32000000000",
            error: (phone) => {
                const regex = /^\+[0-9]*$/
                return (phone && !regex.test(String(phone).toLowerCase()));
            },
            helperText:"core.login.phoneHelper"
        },
        detail:{
            show:false,
        }
    },

    name:{
        id:"name",
        type:ModelTypes.TEXT,
        label:"core.login.name",
        table:{
            show:false
        },
        list:{
            show:false,
        },
        form:{
            show:true,
            id:"name-profile",
            label:"core.login.name",
            select:false,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"name",
            placeholder:"core.login.nameJohn",
            error: (name) => {
                const tab = name ? name.split(" ") : [];
                return name && tab.length < 2;
            },
            helperText:"core.login.nameHelper"
        },
        detail:{
            show:true,
        }
    },

    password:{
        id:"password",
        type:"PASSWORD",
        label:"core.login.password",
        table:{
            show:false,
        },
        list:{
            show:false,
        },
        form:{
            show:true,
            id:"password-profile",
            label:"core.login.password",
            select:false,
            multiline:false,
            password:true,
            rows:1,
            required:true,
            name:"password",
            error: (password) => {
                return (password && !validPassword(password))
            },
            helperText:"core.login.oldPasswordHelper"
        },
        detail:{
            show:false
        }
    },

    confirmPassword:{
        id:"confirm_password",
        type:"PASSWORD",
        label:"core.login.confirmPassword",
        table:{
            show:false,
        },
        list:{
            show:false,
        },
        form:{
            show:true,
            id:"confirmPassword-profile",
            label:"core.login.confirmPassword",
            select:false,
            multiline:false,
            password:true,
            rows:1,
            required:true,
            name:"confirmPassword",
            error: (password, confirmPassword) => {return (confirmPassword && confirmPassword !== password)},
        },
        detail:{
            show:false
        }
    },

    oldPassword:{
        id: "old_password",
        type:"PASSWORD",
        label:"core.profile.oldPassword",
        table:{
            show:false,
        },
        list:{
            show:false,
        },
        form:{
            show:true,
            id:"oldPassword-profile",
            label:"core.profile.oldPassword",
            select:false,
            multiline:false,
            password:true,
            rows:1,
            required:true,
            name:"oldPassword",
        },
        detail:{
            show:false
        }
    },


    lang:{
        id:"lang",
        type:ModelTypes.TEXT,
        label:"core.login.lang",
        table:{
            show:true,
            formatValue: (value) => {return _.upperFirst(getTranslations(getLanguage(), "core.lang." + value))}
        },
        list:{
            show:false,
        },
        form:{
            show:true,
            id:"lang-profile",
            label:"core.login.lang",
            select:true,
            selectOptions:{
                options:[],
                showTextField:false
            },
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"lang"
        },
        detail:{
            show:true,
            translation:"core.lang."
        }
    },

    id_company:{
        id:"id_company",
        type:ModelTypes.NUMBER,
        label:"id_company",
        table:{
            show:false
        },
        list:{
            show:false
        },
        form:{
            show:true,
            id:"company-profile",
            label:"core.company.company",
            select:true,
            selectOptions:{
                options:[],
                showTextField:false
            },
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"id_company"
        },
        detail:{
            show:false
        }
    },

    company_name:{
        id:"company",
        type:ModelTypes.TEXT,
        label:"core.company.company",
        table:{
            show:true
        },
        list:{
            show:false
        },
        form:{
            show:false,
        },
        detail:{
            show:false
        }
    },

    id_roles:{
        id:"id_roles",
        type:ModelTypes.ARRAY,
        label:"id_roles",
        table:{
            show:false,
        },
        list:{

        },
        form:{
            show:true,
            id:"roles-user",
            label:"core.user.role",
            select:true,
            selectOptions:{
                options:[],
                showTextField:false
            },
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"id_roles"
        },
        detail:{
            show:false
        }
    },

    roles:{
        id:"role",
        type:ModelTypes.PRISMA_RELATIONS,
        relationLink:(value) => {return value === "Super-Admin" ? null : value },
        filterValueRelation:(value) => {return value.filter(el => el !== "global_super_admin")},
        label:"core.user.role",
        table:{
            show:true,
        },
        list:{
            show:false,
        },
        form:{
            show:false,
        },
        detail:{
            show:true,
        }

    },
    is_super_admin:{
        id:"is_super_admin",
        type:ModelTypes.BOOLEAN,
        label:"is_super_admin",
        table:{
            show:false,
        },
        list:{

        },
        form:{
            show:false,
        },
        detail:{
            show:false
        }
    },

    teams:{
        id:"teams",
        type:ModelTypes.ARRAY,
        label:"admin.teams",
        table:{
            show:false
        },
        list:{
            show:false
        },
        form:{
            show:true,
            showCondition:(value) => {return value.id_roles.includes(2);},
            type:"secondary",
            id:"teams-profile",
            label:"manager.dashboard.dashboard",
            select:true,
            selectOptions:{
                options:[],
                showTextField:false,
                showAll:true
            },
            multiple:true,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"teams",
            helperText:"admin.advancedUserHelper"

        },
        detail:{
            show:true,
            showCondition:(value) => {return value.id_roles.includes(2);},
            key:"name",
            label:"manager.dashboard.dashboard"
        }
    },

    teams_collab:{
        id:"teams_collab",
        type:ModelTypes.ARRAY,
        label:"admin.teams",
        table:{
            show:false
        },
        list:{
            show:false
        },
        form:{
            show:true,
            showCondition:(value) => {
                return value.id_roles.includes(2);
            },
            type:"secondary",
            id:"teams-collab-profile",
            label:"WeBloom",
            select:true,
            selectOptions:{
                options:[],
                showTextField:false,
                showAll:true
            },
            multiple:true,
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"teams_collab",
            helperText:"admin.advancedUserCollabHelper"
        },
        detail:{
            show:true,
            showCondition:(value) => {return value.id_roles.includes(2);},
            key:"name",
            label:"WeBloom"
        }
    },

    id_team:{
        id:"id_team",
        type:ModelTypes.NUMBER,
        label:"id_team",
        table:{
            show:false
        },
        list:{
            show:false
        },
        form:{
            show:true,
            id:"team-profile",
            label:"admin.team",
            select:true,
            selectOptions:{
                options:[],
                showTextField:false
            },
            multiline:false,
            password:false,
            rows:1,
            required:true,
            name:"id_team"
        },
        detail:{
            show:false
        }
    },

    team:{
        id:"id_team",
        type:ModelTypes.PRISMA_RELATION,
        relationLink:(value) => {return value?.name },
        label:"admin.team",
        table:{
            show:false,
            alternative:false
        },
        list:{
            show:false
        },
        form:{
            show:false,
        },
        detail:{
            show:true
        }
    },

    status:{
        id:"status",
        type:ModelTypes.TEXT,
        label:"admin.status",
        table:{
            show:true,
        },
        list:{
            show:true
        },
        form:{
            show:false
        },
        detail:{
            show:true,
            translation:"admin.",
        }
    },

    filters:{
        id:"filters",
        type:ModelTypes.ARRAY,
        label:"core.filters",
        table:{
            show:false
        },
        list:{

        },
        form:{
            show:true,
            id:"filters-profile",
            label:"core.filters",
            select:true,
            selectOptions:{
                options:[],
                groupBy:"company_filter",
                radioGroupBy:true,
                showTextField:false,
            },
            multiple:true,
            multiline:false,
            password:false,
            rows:1,
            required:false,
            name:"filters"
        },
        detail:{
            show:true,
            key:"name"
        }
    },

    created_at:{
        id:"created_at",
        type:ModelTypes.DATETIME,
        label:"core.created_at",
        table:{
            show:true
        },
        list:{
            show:false,
        },
        form:{
            show:false,
        },
        detail:{
            show:true
        }
    },

    updated_at:{
        id:"updated_at",
        type:ModelTypes.DATE,
        label:"core.updated_at",
        table:{
            show:false
        },
        list:{
            show:false,
        },
        form:{
            show:false,
        },
        detail:{
            show:false
        }
    },

    deleted_at:{
        id:"deleted_at",
        type:ModelTypes.DATE,
        label:"core.deleted_at",
        table:{
            show:false
        },
        list:{
            show:false
        },
        form:{
            show:false,
        },
        detail:{
            show:false
        }
    },
};

export default User;