import React from "react";
import AnswerSmileys from "./AnswerSmileys";
import {Row} from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import {PurpleTextField} from "../../../../Utils/Global";
import IconButton from "@mui/material/IconButton/IconButton";
import flagPurpleIcon from "../../../Core/symbols/flags/flag-purple.svg";
import flagPurpleOutlinedIcon from "../../../Core/symbols/flags/flag-border-purple.svg";
import {useTranslation} from "react-multi-lang/lib";



const ClickAndBloom = (props) => {

    const {question, handleSmileyClick, handleComment, handleFlag, bp} = props;

    const isMobile = bp.isMobileLandscapeOrLess();
    const isTablet = bp.isTablet();

    const t = useTranslation();

    const handleNotApplicable = () => {
        if(question.score === -1){
            handleSmileyClick(null, question);
        }else{
            handleSmileyClick(-1, question);
        }
    }

    return(
        <div className={"m-auto mt-0 " + (isMobile ? "w-full" : "w-3/4")}>
            <AnswerSmileys question={question} handleSmileyClick={handleSmileyClick} {...props} />
            <div className={"text-center m-auto " + (isMobile ? "w-full" : isTablet ? "w-full" : "w-1/2")}>
                <div className={(isMobile ? "ml-0 mb-0 flex" : "ml-30 mb5 flex")}>
                    <IconButton color={"inherit"} className={"-ml-14 icon_button"}>
                        <img alt={"flag"} src={(question && question.score === -1) ? flagPurpleOutlinedIcon : (question && question.flagged) ? flagPurpleIcon : flagPurpleOutlinedIcon} className={(question && question.score === -1) ? "cursorDefault w-24 h-24" : "pointer w-24 h-24"} onClick={() => {handleFlag(question)}}/>
                    </IconButton>
                    <span className={"ebloom-text ebloom-large-text mt10 " + ((question && question.score === -1) ? "cursorDefault text-grey" : "pointer " + ((question && question.flagged) ? "text-contrast-purple" : "text-purple"))} onClick={() => {handleFlag(question)}}>{_.capitalize(t("employee.flagHelper"))}</span>

                </div>
                <div className={(isMobile ? "-ml-15 mb-0 flex" : "ml-15 mb-10 flex")}>
                    <Checkbox className={"checkbox-purple"} checked={(question && question.score === -1)} onChange={handleNotApplicable}/><span className={"mt-11 ebloom-text ebloom-large-text text-" + (question && question.score === -1 ? "contrast-purple" : "purple")}>{_.capitalize(t("employee.notApplicable"))}</span>
                </div>
                <Row className={"m-auto mt10 " + (isMobile ? "w-250" : "w-full")}>
                    <PurpleTextField className={((question && question.score === 1) || (question && question.score === 2)) ? "text-purple border border-contrast-purple mb-20" : "mb-20"} disabled={(question && question.score === -1)} fullWidth multiline placeholder={_.capitalize(t("employee.commentHelper"))} value={(question && question.comment) ? question.comment : ""} variant="outlined" onChange={handleComment(question)}/>
                </Row>
            </div>
        </div>
    )
}



export default ClickAndBloom;