import React, {useEffect, useState} from "react";
import HttpApi from "../../../../../httpApi";
import {
    createSuccessNotification, notifyError
} from "../../../../../Utils/Notification";
import _ from "lodash";
import {ListGroup} from "react-bootstrap";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import Divider from "@mui/material/Divider/Divider";
import QuestionList from "../../../../Core/Modules/Views-Tables-Lists/QuestionList";
import {useParams} from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import {useTranslation} from "react-multi-lang/lib";
import BrowseLibraryTour from "../../../../../Utils/ProductTour/Tours/BrowseLibrary/BrowseLibraryTour";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import {getUrl, TourType} from "../../../../../Utils/Global";
import {useUserData} from "../../../../../hooks/userHook";


const Library = (props) => {
    const [playlistsContainingQuestion, setPlaylistContainingQuestion] = useState([]);
    const [playlistsNotContainingQuestion, setPlaylistNotContainingQuestion] = useState([]);
    const [questionId, setQuestionId] = useState(null);
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [tablePage, setTablePage] = useState(0);

    const t = useTranslation();
    const params = useParams();
    const user = useUserData()

    const hasParams = !!params.idEntity
    const isSuperAdmin = user.roles.includes("global_super_admin");

    const {companyToEdit,selectedEntity} = props;

    const fetchQuestions = () => {
        let url = "/questions/getAllForAdmin"
        if((hasParams || isSuperAdmin) && (selectedEntity || params.idEntity)){
            const idEntity = selectedEntity  ? selectedEntity : params.idEntity
            url = "/questions/getAllForAdmin/" + idEntity
        }
        if(url){
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    setQuestions(response.data);
                }else{
                    setQuestions([]);
                }
            }).catch(error => {
                notifyError(error);
            });
        }

    };

    const fetchPlaylists = (id_question) => {
        let url = "/playlist/getNotContainingQuestion/" + id_question
        if(hasParams || selectedEntity){
            const idEntity = selectedEntity  ? selectedEntity : params.idEntity
            url = "/playlist/group/getNotContainingQuestion/" + id_question + "/" + idEntity
        }
        HttpApi.get(url).then(results => {
            if(results && results.data){
                setPlaylistNotContainingQuestion(results.data);
            }else{
               setPlaylistNotContainingQuestion([]);
            }
        }).catch(error => {
            console.log(error);
        });

        let url2 = "/playlist/getContainingQuestion/" + id_question
        if(hasParams || selectedEntity){
            const idEntity = selectedEntity  ? selectedEntity : params.idEntity
            url2 = "/playlist/group/getContainingQuestion/" + id_question + "/" + idEntity
        }
        HttpApi.get(url2).then(results => {
            if(results && results.data){
                setPlaylistContainingQuestion(results.data);
            }else{
                setPlaylistContainingQuestion([]);
            }
        }).catch(error => {
            console.log(error);
        })
    };



    const handleAdd = (id_playlist) => event => {
        event.preventDefault();
        if(id_playlist && questionId){
            let url = "/playlist/addQuestion"
            let data = {id_playlist:id_playlist, id_question:questionId}
            if(hasParams || selectedEntity){
                const idEntity = selectedEntity  ? selectedEntity : params.idEntity
                url = "/playlist/addQuestionGroup"
                data.id_company = idEntity
            }
            HttpApi.post(url,data).then(() => {
                createSuccessNotification("Success !", "Question successfully added to your playlist");
                fetchPlaylists(questionId);
            }).catch(error => {
                notifyError(error);
            })
        }
    };

    const handleRemove = (id_playlist) => event => {
        event.preventDefault();
        if(id_playlist && questionId){
            let url = "/playlist/removeQuestion"
            let data = {id_playlist:id_playlist, id_question:questionId}
            if(hasParams || selectedEntity){
                const idEntity = selectedEntity  ? selectedEntity : params.idEntity
                url = "/playlist/removeQuestionGroup"
                data.id_company = idEntity
            }
            HttpApi.post(url,data).then(() => {
                createSuccessNotification("Success !", "Question successfully removed from your playlist");
                fetchPlaylists(questionId);
            }).catch(error => {
                notifyError(error)
            })
        }
    };

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }

    useEffect(() => {
        fetchQuestions();
    }, [params.idEntity, companyToEdit,selectedEntity]);


    const toggleDrawer = (toEdit) => event => {
        event.preventDefault();
        if(toEdit && toEdit > 0){
            fetchPlaylists(toEdit);
            setQuestionId(toEdit);
        }else{
            setQuestionId(null);
        }
        setSideFormOpen(toEdit > 0);

    };


    return(
        <div>
            <BrowseLibraryTour/>
            <CardDefault title={_.capitalize(t("core.playlist.library"))} tourTooltip={true} showButton={false}  leftButtonText={_.capitalize(t("core.back"))}  tourType={TourType.BROWSE_LIBRARY}>
                <QuestionList questions={questions} showAction={!companyToEdit} buttonType="add" handleAction={toggleDrawer} showPagination tablePage={tablePage} handleTablePage={handleTablePage} showDiagnostic showLastUsed showTag entityId={selectedEntity}/>
            </CardDefault>
            <SideForm formTitle={"Playlists"} showButtons={false} sideFormOpen={sideFormOpen} toggleDrawer={toggleDrawer}>
                <ListGroup variant="flush">
                    {
                        (playlistsNotContainingQuestion && playlistsNotContainingQuestion.length > 0) &&
                        playlistsNotContainingQuestion.map(playlist => (
                            <ListGroup.Item>
                                <span>{playlist.name}</span>
                                <span style={{float:"right", marginTop:-10}}><Checkbox className={"checkbox-purple"} onChange={handleAdd(playlist.id)}/></span>
                            </ListGroup.Item>
                        ))
                    }
                    <Divider/>
                    {
                        (playlistsContainingQuestion && playlistsContainingQuestion.length > 0) &&
                        playlistsContainingQuestion.map(playlist => (
                            <ListGroup.Item>
                                    <span>{playlist.name}</span>
                                    <span style={{float:"right", marginLeft:15, marginTop:-10}}><Checkbox className={"checkbox-purple"} checked onChange={handleRemove(playlist.id)}/></span>
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
            </SideForm>
        </div>
    )


}


export default Library;