import {getLanguage} from "react-multi-lang";
import _ from "lodash";


export const getLanguages = () => {
    return [
        {value:"en", name:_.upperFirst(getTranslations(getLanguage(), "core.lang.en"))},
        {value:"fr", name: _.upperFirst(getTranslations(getLanguage(), "core.lang.fr"))},
        {value:"nl", name:_.upperFirst(getTranslations(getLanguage(), "core.lang.nl"))}
    ]
};

/**
 * lang: translation language
 * text: key in the translation file
 * param: [{key, value }], variable to update in text
 **/

export const getTranslations = (lang, text, param) => {
    if(!lang){
        lang = 'en';
    }
    const f = require("./dictionary/" + lang + ".json");

    let jsonText = "";

    let array = text.split(".");
    switch (array.length){
        case 1 :
            jsonText = f[array[0]];
            break;
        case 2 :
            jsonText = f[array[0]][array[1]];
            break;
        case 3 :
            jsonText = f[array[0]][array[1]][array[2]];
            break;
        case 4:
            jsonText = f[array[0]][array[1]][array[2]][array[3]]
            break;
        default :
            jsonText = f[text];
            break;
    }

    if(param){
        Object.entries(param).forEach(([key, value]) => {
            const k = "{" + key + "}";
            const pattern = new RegExp(k,"ig");
            jsonText = jsonText.replace(pattern, value.toString())
        })
    }

    return jsonText;
}