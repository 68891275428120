import React, {useEffect, useState} from "react";
import HttpApi from "../../../../../httpApi";
import {
    createSuccessNotification,
    notifyError
} from "../../../../../Utils/Notification";
import QuestionList from "../../../../Core/Modules/Views-Tables-Lists/QuestionList";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";


const PlaylistEditor = (props) => {
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [playlist, setPlaylist] = useState({});
    const [questions, setQuestions] = useState([]);
    const [questionsNotInPlaylist, setQuestionsNotInPlaylist] = useState([]);


    const fetcheBloomSelection = () => {
        HttpApi.get("/playlist/backoffice/geteBloomSelection").then(response => {
            if(response && response.data){
                setPlaylist(response.data);
                setQuestions(response.data.questions);
                fetchQuestionNotInPlaylist(response.data.id);
            }
        }).catch(error => {
            notifyError(error);
        });
    };

    const fetchQuestionNotInPlaylist = (id_playlist=null) => {
        HttpApi.getV2("/questions/getNotInPlaylist/" + id_playlist || playlist.id).then(response => {
            if(response && response.data){
                setQuestionsNotInPlaylist(response.data);
            }
        }).catch(error => {
            notifyError(error);
        })
    };

    const handleAdd = (id_question) => event => {
        event.preventDefault();
        HttpApi.post("/playlist/backoffice/addToEBloomSelection", {id_question:id_question}).then(() => {
            createSuccessNotification("Success !", "Question successfully added to your playlist");
            fetcheBloomSelection();
        }).catch(error => {
           notifyError(error);
        })

    };

    const handleRemove = (id_question) => event => {
        event.preventDefault();
        HttpApi.post("/playlist/backoffice/removeFromEBloomSelection", {id_question: id_question}).then(() => {
            createSuccessNotification("Success !", "Question successfully removed from your playlist");
            fetcheBloomSelection();
        }).catch(error => {
           notifyError(error);
        })
    };

    useEffect(() => {
        fetcheBloomSelection();
    }, []);



    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setSideFormOpen(open);

    };

    return(
            <>
                <CardDefault title={"eBloom Selection"} handleRightButton={toggleDrawer(true)}>
                    {
                        questions &&
                        <QuestionList questions={questions} handleAction={handleRemove} showAction={true} buttonType={"remove"}/>
                    }

                </CardDefault>
                <SideForm toggleDrawer={toggleDrawer} sideFormOpen={sideFormOpen} showButtons={false} formTitle={"core.add"}>
                    <QuestionList questions={questionsNotInPlaylist} handleAction={handleAdd} buttonType={'add'}/>
                </SideForm>
            </>
    )
}

export default PlaylistEditor;