import React, {useState} from "react";
import DisplayAdminView from "../../Layouts/DisplayAdminView";
import {Container} from "react-bootstrap";
import Frequency from "./Frequency";
import {useUserData} from "../../../../hooks/userHook";
import EntitiesSwitcher from "../../../Core/Modules/Button/EntitiesSwitcher";



const FrequencyPage = (props) => {
    const [selectedEntity, setSelectedEntity] = useState(null);

    const user = useUserData()
    return(
        <DisplayAdminView {...props} menu={"settings"} pageName={"admin.frequency"} selectedIndex={9}>
            {
                () => (
                    <Container>
                        {
                            user.isSuperAdmin && user.companyGroup !== null &&
                            <div className={"mb-10"}>
                                <EntitiesSwitcher handleSwitch={(value) => {setSelectedEntity(value)}} entity={selectedEntity} alternative={true}/>
                            </div>
                        }
                        <Frequency selectedEntity={selectedEntity} {...props}/>
                    </Container>
                )
            }

        </DisplayAdminView>
    )


};

export default FrequencyPage