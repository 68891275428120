import React, {useEffect, useState} from "react";
import HttpApi from "../../../../httpApi";
import {Card, ListGroup, Row, Col, Button, Modal} from "react-bootstrap";
import _ from "lodash";
import {Colors} from "../../../../Utils/Colors";
import {
    createSuccessfullyUpdatedNotification,
    createSuccessNotification,
    notifyError
} from "../../../../Utils/Notification";
import CustomButton from "../../../Core/Modules/Button/CustomButton";
import {
    FullContrastPurpleTextField,
    getUrl,
    isNullOrUndefined,
    PurpleSwitch,
    SettingTypes
} from "../../../../Utils/Global";
import {useTranslation} from "react-multi-lang";
import {MenuItem} from "@mui/material";
import EbloomTooltip from "../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardDefault from "../../../Core/Modules/Cards/CardDefault";
import {useNavigate} from "react-router-dom";
import {useUserData} from "../../../../hooks/userHook";

const Frequency = (props) => {
    const [loading, setLoading] = useState(false);
    const [frequency, setFrequency] = useState({});
    const [week, setWeek] = useState({monday:[], tuesday:[], wednesday:[], thursday:[], friday:[]});
    const [nbCB, setNbCB] = useState(0);
    const [maxCB, setMaxCB] = useState(5);
    const [show, setShow] = useState(false);
    const [publicHoliday, setPublicHoliday] = useState(false);
    const [recurrency, setRecurrency] = useState(0);
    const recurrencies = [{value:1, name:"core.everyWeek"}, {value:2, name:"core.everyTwoWeeks"}, {value:3, name:"core.everyThreeWeeks"}, {value:4, name:"core.everyFourWeeks"}];

    const {companyToEdit,selectedEntity} = props;

    const t = useTranslation();
    const navigate = useNavigate();
    const user = useUserData();

    const isSuperAdmin = user.roles.includes("global_super_admin");


    const displayMaxCB = maxCB === 1000 ? t("core.unlimited") : maxCB;

   const checkUpdateFrequency = () => {
        if(frequency.active === 1){
            handleOpenModal();
        }else{
            updateFrequency();
        }
   }

   const updateFrequency = () => {
       if(!loading){
           setLoading(true);
           const data = {monday:week.monday.length,
               tuesday:week.tuesday.length,
               wednesday: week.wednesday.length,
               thursday:week.thursday.length,
               friday:week.friday.length,
               recurrency:recurrency};
           let url1 = "/frequencies/update"
           let url2 = "/settings/updateFromType/"+ SettingTypes.PUBLIC_HOLIDAY
           if(selectedEntity){
               url2 = "/settings/group/updateFromType/"+ SettingTypes.PUBLIC_HOLIDAY+"/"+selectedEntity
               url1 = "/frequencies/update/"+selectedEntity
           }
           const dataSetting = {value:Number(publicHoliday), type:SettingTypes.PUBLIC_HOLIDAY}
           HttpApi.postV2(url1, data).then(() => {
               HttpApi.put(url2, dataSetting).then(() => {
                   createSuccessfullyUpdatedNotification("frequency");
                   setLoading(false);
                   if(companyToEdit){
                       navigate("/backoffice/daily");
                   }else if(selectedEntity === null){
                       navigate("/admin/questions/planner");
                   }
               }).catch(error => {
                   setLoading(false);
                   notifyError(error);
               })
           }).catch(error => {
               setLoading(false);
               notifyError(error);
           });
       }

   };

   const handleSwitch = (event) => {
       setPublicHoliday(event.target.checked);
   }

    const addFrequency = (day) => event => {
        setWeek(prevState => {
            let week = {...prevState};
            week[day].push("Click&Bloom");
            return week;
        })
        setNbCB(nbCB + 1);

    };

    const removeFrequency = (day) => event => {
        setWeek(prevState => {
            let week = {...prevState};
            week[day].pop();
            return week;
        })
        setNbCB(nbCB-1);
    };

    const fetchFrequency = () => {
        let url ="/frequencies/getForCompany"
        if (isSuperAdmin && !isNullOrUndefined(selectedEntity)){
            url ="/frequencies/getForCompany/" +selectedEntity
            setWeek({monday:[], tuesday:[], wednesday:[], thursday:[], friday:[]})
            setNbCB(0)
            setRecurrency(0)
        }
        if((isSuperAdmin && !isNullOrUndefined(selectedEntity)) || !isSuperAdmin){
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    let count = 0;
                    Object.entries(response.data).forEach(([key,value]) => {
                        if(key === "monday" || key === "tuesday" || key === "wednesday" || key === "thursday" || key === "friday"){
                            count += value;
                            setWeek(prevState => {
                                let week = {...prevState};
                                for(let i=0;i<value;i++){
                                    week[key][i] = "Click&Bloom"
                                }
                                return week;
                            })
                        }
                    });
                    setNbCB(count);
                    setFrequency(response.data);
                    setRecurrency(response.data.recurrency);
                }
            }).catch(error => {
                notifyError(error);
            })
        }

    };

    const fetchNbCb = () => {

        let url = "/settings/getForCompany/"+SettingTypes.NB_CB
        if (isSuperAdmin && !isNullOrUndefined(selectedEntity)){
            url = "/settings/group/getForCompany/"+SettingTypes.NB_CB+"/"+selectedEntity
        }
        if((isSuperAdmin && !isNullOrUndefined(selectedEntity)) || !isSuperAdmin){
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    setMaxCB(response.data.value);
                }else{
                    setMaxCB(10);
                }
            })
        }

    }

    const fetchPublicHolidaySetting = () => {
        let url = "/settings/getForCompany/"+SettingTypes.PUBLIC_HOLIDAY
        if (isSuperAdmin && !isNullOrUndefined(selectedEntity)){
            url = "/settings/group/getForCompany/"+SettingTypes.PUBLIC_HOLIDAY+"/"+selectedEntity
        }
        if((isSuperAdmin && !isNullOrUndefined(selectedEntity)) || !isSuperAdmin){
            HttpApi.getV2(url).then(response => {
                if(response && response.data){
                    setPublicHoliday(response.data.value);
                }else{
                    setPublicHoliday(false);
                }
            }).catch(error => {
                notifyError(error);
            })
        }

    }

    const startAutoPlanner = (type) => event => {
        event.preventDefault();
        const data = {monday:week.monday.length,
            tuesday:week.tuesday.length,
            wednesday: week.wednesday.length,
            thursday:week.thursday.length,
            friday:week.friday.length,
            active: 1,
            id_playlist:frequency.id_playlist,
            recurrency:recurrency};
        let url = type === "now" ? "/questions/autoPlannerWithFrequency/now" : "/questions/autoPlannerWithFrequency/next";
        let urlSetting = "/settings/updateFromType/"+SettingTypes.PUBLIC_HOLIDAY
        let urlUpdate = "/frequencies/update"

        if(selectedEntity){
            url = type === "now" ? "/questions/autoPlannerWithFrequency/now/"+selectedEntity : "/questions/autoPlannerWithFrequency/next/"+selectedEntity;
            urlSetting = "/settings/group/updateFromType/"+SettingTypes.PUBLIC_HOLIDAY+"/"+selectedEntity
            urlUpdate = "/frequencies/update/"+selectedEntity
        }

        const dataSetting = {value:Number(publicHoliday), type:SettingTypes.PUBLIC_HOLIDAY}
        HttpApi.put(urlSetting,dataSetting ).then(() => {
            HttpApi.postV2(url, data).then(() => {
                let message = "Your automatic planner has reloaded. It will automatically insert Click&Bloom according to your new frequency";
                createSuccessNotification("Automatic Planner", message);
                setShow(false);
                HttpApi.postV2(urlUpdate, data).then(() => {
                    createSuccessfullyUpdatedNotification("frequency");
                    navigate("/admin/questions/planner");
                }).catch(error => {
                    notifyError(error);
                })
            }).catch(error => {
                notifyError(error);
            })
        }).catch(error => {
            notifyError(error);
        })

    }

    const handleCloseModal = () => {
        setShow(false);
    }

    const handleOpenModal = () => {
        setShow(true);
    }

    const handleChangeRecurrency = (event) => {
        setRecurrency(event.target.value);
    }

    useEffect(() => {
        fetchFrequency();
        fetchNbCb();
        fetchPublicHolidaySetting();
    }, [companyToEdit,selectedEntity])


        return(
            <>
                <CardDefault showButton={false} subtitle={_.upperFirst(t("admin.frequencyHelper"))} title={_.upperFirst(t("admin.frequency"))}>
                    <Row style={{width:"100%", marginBottom:20}}>
                        <div style={{width:"100%", textAlign:"right", color:Colors.EBLOOM_CONTRAST_PURPLE}} className={"ebloom-normal-text"}>{_.upperFirst(t("admin.cbUsed"))} <span style={{color:Colors.EBLOOM_CONTRAST_BLUE, fontSize:16, fontWeight:"bold"}}>{nbCB}</span>/{displayMaxCB}</div>
                    </Row>
                    <Row md={5} style={{margin:"auto",marginBottom:20}}>
                        {
                            Object.entries(week).map(([day, values], index) => (
                                <Col style={{padding:2}} key={index}>
                                    <Card style={{minWidth:190, borderColor:Colors.EBLOOM_CONTRAST_PURPLE}}>
                                        <Card.Header style={{textAlign:"center", backgroundColor:Colors.EBLOOM_CONTRAST_PURPLE, color:"white"}} className={"ebloom-text ebloom-large-text"}>{_.capitalize(t("core.day." + day))}</Card.Header>
                                        <Card.Body>
                                            <ListGroup variant={"flush"}>
                                                {
                                                    values.map((value, index) => (
                                                        <ListGroup.Item key={index} style={{paddingLeft:0, paddingRight:0}}>
                                                            <div style={{textAlign:"center"}}>
                                                                <span style={{float:"left"}}>{value}</span>
                                                                <CustomButton type={"remove"} border style={{float:"right"}} handleClick={removeFrequency(day)}/>
                                                            </div>
                                                        </ListGroup.Item>
                                                    ))

                                                }
                                                {nbCB < maxCB &&
                                                    <ListGroup.Item className={"center"} style={{paddingTop:values.length === 0 ? 0 : 15, paddingBottom:0}}>
                                                        <AddCircleOutlineIcon className={"ebloom-add-btn-purple"} onClick={addFrequency(day)}/>
                                                    </ListGroup.Item>
                                                }


                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                    <Row>
                        <div style={{width:"100%", textAlign:"center", padding:20}}>
                            <span style={{float:"left", marginTop:10, marginRight:5}} className={"ebloom-normal-text cursorDefault"}>{_.upperFirst(t("admin.applyFrequency"))}:</span>
                            <FullContrastPurpleTextField style={{float:"left", width:200}} variant={"outlined"} size={"small"} select onChange={handleChangeRecurrency} value={recurrency}>
                                {
                                    recurrencies.map((option, index) => (
                                        <MenuItem key={index} value={option.value} className={"ebloom-normal-text"}>{t(option.name)}</MenuItem>
                                    ))
                                }
                            </FullContrastPurpleTextField>
                        </div>
                    </Row>
                    <Row>
                        <div style={{width:"100%", padding:20}}>
                            <EbloomTooltip text={_.upperFirst(t("admin.publicHolidayHelperTooltip"))}>
                                <span className={"cursorDefault"}>{_.upperFirst(t("admin.publicHolidayHelper"))}</span>
                            </EbloomTooltip>
                            <PurpleSwitch checked={publicHoliday} onChange={handleSwitch}/>
                            <Button style={{float:"right"}} className={"ebloom-btn-purple"} onClick={checkUpdateFrequency}>{_.capitalize(t("core.save"))}</Button>
                        </div>
                    </Row>
                </CardDefault>
                <Modal show={show} onHide={handleCloseModal} style={{borderColor:Colors.EBLOOM_CONTRAST_BLUE}}>
                    <Modal.Header closeButton style={{backgroundColor:Colors.EBLOOM_CONTRAST_BLUE}}>
                        <Modal.Title>{_.capitalize(t("admin.frequencyAutoPlannerTitle"))}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}}>
                        <p>{_.upperFirst(t("admin.frequencyAutoPlannerHelper"))}</p>
                    </Modal.Body>
                    <Modal.Footer style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}}>
                        <Button variant={"secondary"} className={"ebloom-normal-text"} onClick={handleCloseModal}>{_.capitalize(t("core.cancel"))}</Button>
                        <Button className={"ebloom-btn-purple ebloom-normal-text"} onClick={startAutoPlanner("now")}>{_.upperCase(t("core.startNow"))}</Button>
                        <Button className={"ebloom-btn-purple ebloom-normal-text"} onClick={startAutoPlanner("nextWeek")}>{_.upperCase(t("core.startNext"))}</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )

}

export default Frequency;